import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
public isStep1:boolean;
public isStep2:boolean;
public isStep3:boolean;
public isStep4:boolean;
public isStep5:boolean;
public isStep6:boolean;
public buttonText:string = "Expand All";
public isCollapse:boolean;
public isCaregiverFaq:boolean =true;
public isRecruiterFaq:boolean =false;
public isRecBtn:boolean;
public isCareBtn:boolean;
loginStatus$: Observable<boolean>;
  caregiverLogin$:Observable<boolean>;
  recruiterLogin$:Observable<boolean>;
  constructor(
    public authenticationService:AuthenticationService
  ) {
     }

  ngOnInit(): void {
    setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 10);
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.loginStatus$ = this.authenticationService.isLoggedIn;
    this.caregiverLogin$ = this.authenticationService.isCaregiver;
    this.recruiterLogin$ = this.authenticationService.isRecruiter;
    this.loginStatus$.subscribe(data=>{
      console.log("data", data);
      if(!data){
        document.getElementById('faqScreen').style.width = '100%';
        this.isRecBtn=true;
         this.isCareBtn = true;
         this.isCaregiverFaq = true;
    this.isRecruiterFaq = false;
         setTimeout(() =>{
          document.getElementById('faqScreen').style.height ='auto';
         document.getElementById('caregiverFaqBtn').style.backgroundColor ="#AA004D";
    document.getElementById('recruiterFaqBtn').style.backgroundColor ="#2777BA";
         }, 10)
      //  document.getElementById('faqScreen').style.paddingTop = '40px';
       }
       else if(data){
         this.isRecBtn=false;
         this.isCareBtn = false;
       }
    })
    this.caregiverLogin$.subscribe(data=>{
      if(data){
        this.isCaregiverFaq = true;
        this.isRecruiterFaq = false;
       }
    })
    this.recruiterLogin$.subscribe(data=>{
      if(data){
        this.isRecruiterFaq = true;
        this.isCaregiverFaq = false;
        document.getElementById('faqScreen').style.height = '690px';
       }
    })
  }
  expandOrCollapse(value) {
    if(value == true){
      this.buttonText = "Collapse All";
      this.isStep1 = true;
      this.isStep2 = true;
      this.isStep3 = true;
      this.isStep4 = true;
      this.isStep5 = true;
      this.isStep6 = true;
      document.getElementById('faqScreen').style.height = 'auto';
      let len = document.getElementsByClassName('steps').length
      for(let i=1;i<=len;i++){
        document.getElementById('Step'+i).classList.add('allSteps');
      }
    }
    else if(value == false){
      this.buttonText = "Expand All";
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      this.isStep5 = false;
      this.isStep6 = false;
      if(this.isRecruiterFaq){
     document.getElementById('faqScreen').style.height = '690px';
      }else {
        document.getElementById('faqScreen').style.height = 'auto';
      }
      let len = document.getElementsByClassName('steps').length
      for(let i=1;i<=len;i++){
        document.getElementById('Step'+i).classList.remove('allSteps');
      }
    }
  }
  caregiverFaq() {
    document.getElementById('caregiverFaqBtn').style.backgroundColor ="#AA004D";
    document.getElementById('recruiterFaqBtn').style.backgroundColor ="#2777BA";
    this.isCaregiverFaq = true;
    this.isRecruiterFaq = false;
    this.expandOrCollapse(false);
  }
  recruiterFaq() {
    document.getElementById('caregiverFaqBtn').style.backgroundColor ="#2777BA";
    document.getElementById('recruiterFaqBtn').style.backgroundColor ="#AA004D";
    this.isCaregiverFaq = false;
    this.isRecruiterFaq = true;
    this.expandOrCollapse(false);
  }
  step(value){
    var helpStep1 = document.getElementById('Step1');
    var helpStep2 = document.getElementById('Step2');
    var helpStep3 = document.getElementById('Step3');
    var helpStep4 = document.getElementById('Step4');
    var helpStep5 = document.getElementById('Step5');
    var helpStep6 = document.getElementById('Step6');
    /*var helpStep7 = document.getElementById('Step7');
    var helpStep8 = document.getElementById('Step8');*/
     if(value === 'step1'){
       this.isStep1 = !this.isStep1;
       if(this.isStep1){
        helpStep1.classList.add('allSteps');
       }
       else{
        helpStep1.classList.remove('allSteps');
       }
     }
     else if(value === 'step2'){
       this.isStep2 = !this.isStep2;
       if(this.isStep2){
        helpStep2.classList.add('allSteps');
       }
       else{
        helpStep2.classList.remove('allSteps');
       }
     }
     else if(value === 'step3'){
       this.isStep3 = !this.isStep3;
       if(this.isStep3){
        helpStep3.classList.add('allSteps');
      }
      else{
       helpStep3.classList.remove('allSteps');
      }
     }
     else if(value === 'step4'){
       this.isStep4 = !this.isStep4;
       if(this.isStep4){
        helpStep4.classList.add('allSteps');
      }
      else{
       helpStep4.classList.remove('allSteps');
      }
     }
     else if(value === 'step5'){
       this.isStep5 = !this.isStep5;
       if(this.isStep5){
         helpStep5.style.backgroundColor = "#AA004D";
         helpStep5.style.color = "#ffffff";
       }
       else{
         helpStep5.style.backgroundColor = "#ffffff";
         helpStep5.style.color = "#2777BA";
       }
     }
     else if(value === 'step6'){
       this.isStep6 = !this.isStep6;
       if(this.isStep6){
         helpStep6.style.backgroundColor = "#AA004D";
         helpStep6.style.color = "#ffffff";
       }
       else{
         helpStep6.style.backgroundColor = "#ffffff";
         helpStep6.style.color = "#2777BA";
       }
     }
    /* else if(value === 'step7'){
       this.isStep7 = !this.isStep7;
       if(this.isStep7){
         helpStep7.style.backgroundColor = "#AA004D";
         helpStep7.style.color = "#ffffff";
       }
       else{
         helpStep7.style.backgroundColor = "#ffffff";
         helpStep7.style.color = "#2777BA";
       }
     }
     else if(value === 'step8'){
       this.isStep8 = !this.isStep8;
       if(this.isStep8){
         helpStep8.style.backgroundColor = "#AA004D";
         helpStep8.style.color = "#ffffff";
       }
       else{
         helpStep8.style.backgroundColor = "#ffffff";
         helpStep8.style.color = "#2777BA";
       }
     }*/
     if(this.isRecruiterFaq && !this.isCaregiverFaq){
     if(this.isStep1 || this.isStep2 || this.isStep3 && this.isStep4){
      document.getElementById('faqScreen').style.height = 'auto';
    }
    else {
      document.getElementById('faqScreen').style.height = '690px';
    }
  }
    if((this.isStep1 && this.isStep2 && this.isStep3 && this.isStep4 && this.isStep5 && this.isStep6 && this.isCaregiverFaq) || (this.isStep1 && this.isStep2 && this.isStep3 && this.isStep4 && this.isRecruiterFaq)){
      this.isCollapse = true;
      this.buttonText = "Collapse All";
    }
    else {
      this.isCollapse = false;
      this.buttonText = "Expand All";
    }
   }

}
