import { Component, ElementRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import * as fileSaver from 'file-saver';
import * as fileViewer from '@taldor-ltd/angular-file-viewer';
import { AgGridAngular, ICellRendererAngularComp } from "ag-grid-angular";
import { ShareDocumentsService } from "src/app/shared-services/share-documents.service";
import { map, mergeMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ArrayType } from "@angular/compiler";
import { FileMimeType, FileViewerComponent } from "@taldor-ltd/angular-file-viewer";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AddDocumentsComponent } from "src/app/documents/add-documents/add-documents.component";
import { ProfileComponent } from "src/app/authentication/profile/profile.component";
import { AdminService } from "src/app/shared-services/adminservice.service";
import { EditProfileComponent } from "../edit-profiles/edit-profile.component";
import { UserService } from "src/app/shared-services/userservice.service";
@Component({
    selector: 'action-component',
    template:`
         <a><i class="fa fa-edit" (click)="editbtn()" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Edit"></i></a>
         <a><i *ngIf="!isRecruiter && !isAdmin" class="fa fa-folder-plus" (click)="addDoc()" style="color:#2777BA;font-size:18px;margin-left:20px;" title="add document"></i></a>
     <a><i (click)="confirmDelete()" *ngIf="!isDelete" class="fa fa-trash" title="Delete" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Delete"></i>
     <a><i *ngIf="isDelete" class="fa fa-spinner" style="color:#2777BA;font-size:18px;margin-left:20px;"></i></a>
     <!-- confirmation for delete-->
     <a><i *ngIf="!isBlock && !isBlockButton" class="fa fa-ban" style="color:#2777BA;font-size:18px;margin-left:20px;" (click)="confirmBlock()" title="Block"></i></a>
     <a><i *ngIf="!isBlock && isBlockButton" class="fa fa-ban" style="color:#2777BA;font-size:18px;margin-left:20px;" (click)="confirmUnBlock()" title="UnBlock"></i></a>
     <a><i *ngIf="isBlock" class="fa fa-spinner" style="color:#2777BA;font-size:18px;margin-left:20px;"></i></a>
<ng-template #ConfirmDelete>

   <div>
     <h4 class="modal-title w-100">Are you sure you want to delete this user permanently?</h4>	
   </div>
   <div style="text-align:center">
   <button style="outline:none;" type="button" class="btn btn-danger" (click)="deleteUser()" mat-button mat-dialog-close>Delete</button>
   <button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>Cancel</button>
   </div>

</ng-template> 
<ng-template #ConfirmBlock>

   <div>
     <h4 *ngIf="!isBlockButton" class="modal-title w-100">Are you sure you want to block this user?</h4>	
     <h4 *ngIf="isBlockButton" class="modal-title w-100">Are you sure you want to unBlock this user?</h4>
   </div>
   <div style="text-align:center">
   <button *ngIf="!isBlockButton" style="outline:none;" type="button" class="btn btn-danger" (click)="blockUser()" mat-button mat-dialog-close>Block</button>

   <button *ngIf="isBlockButton" style="outline:none;" type="button" class="btn btn-danger" (click)="unBlockUser()" mat-button mat-dialog-close>UnBlock</button>

   <button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>Cancel</button>
   </div>

</ng-template> 
   `,
   styles:[`
   .fa:hover{
     cursor:pointer;
  }
  .fa-trash, fa-arrow-circle-o-down, .fa-eye{
    transition:All .2s ;
  }
  i.fa-trash:hover {
    color:red !important;
    font-size:20px !important;
  }
  .fa-arrow-circle-o-down:hover, .fa-eye:hover {
    font-size:20px !important;
  }
  ::ng-deep .cdk-overlay-backdrop {
   /* position: static !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    z-index: auto !important;
    pointer-events: none !important;*/
    -webkit-tap-highlight-color: unset !important;
    transition: unset !important;
    opacity: unset !important;
  }
  
 `]
  
})
export class AdminActionComponent implements OnInit {
    public params: any;
    public gridApi;
    public gridColumnApi;
    public isDelete:boolean;
    public isBlock:boolean;
    public isDownload:boolean;
    public isDownloaded:boolean;
    public isBlockButton:boolean;
    public isRecruiter:boolean;
    public isAdmin:boolean;
   // public showFile:boolean;
    @ViewChild("ConfirmDelete") ConfirmDelete:TemplateRef<any>; /** confirmation pop-up for delete doc */
    @ViewChild("ConfirmBlock") ConfirmBlock:TemplateRef<any>; /** confirmation pop-up for delete doc */
    @Output('showFile') showFile:boolean;
    agInit(params: any): void {
        this.params = params;
        if(this.params.data.blockUser == true){
        this.isBlockButton = true;
        }else{
          this.isBlockButton = false;
        }
        if(this.params.data.roleId == 2){
          this.isRecruiter = true;
          }else{
            this.isRecruiter = false;
          }
          if(this.params.data.roleId == 3){
            this.isAdmin = true;
          }else{
            this.isAdmin = false;
          }
     
      }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   
  }
  ngOnInit(){
let canvas = document.getElementById("myCanvas");

  }
  constructor(
    public dialog: MatDialog,
    public adminService:AdminService,
    public sharedocumentsService:ShareDocumentsService,
    public adminSerive:AdminService,
    public sanitizer:DomSanitizer
  ){
  }
  confirmDelete(){ /** trigger when click on delete icon */
    this.dialog.open(this.ConfirmDelete);
  }
  confirmBlock(){ /** trigger when click on block icon */
  this.dialog.open(this.ConfirmBlock);
}
confirmUnBlock(){ /** trigger when click on block icon */
  this.dialog.open(this.ConfirmBlock);
}
  editbtn(){ /** trigger when click on edit icon */
  const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    
    dialogConfig.data =  this.params.data ;
      this.adminSerive.UserProfile(this.params.data.userId)
      .subscribe((res)=>{
        this.dialog.open(EditProfileComponent, dialogConfig);
    this.adminService.raiseProfileEvent(res);
      },
      (err)=>{
      }
      )
    
    
  }
  addDoc(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data =  this.params.data ;
    this.dialog.open(AddDocumentsComponent, dialogConfig);
  }
  deleteUser(){ /** trigger when click on yes button delete confirmation pop-up */
  this.isDelete = true;
  this.adminSerive.deleteUserByAdmin(this.params.data.userId)
  .subscribe((res)=>{
    console.log(res);
    if(res){  
      this.isDelete = false;
      this.adminSerive.raiseEventForUser();
    }
    return res;
  })
}
blockUser(){ /** trigger when click on yes button delete confirmation pop-up */
const inputObj:any={};
  this.isBlock = true;
  inputObj.userId = this.params.data.userId;
  inputObj.blockUser = true;
  inputObj.email =this.params.data.email;
  inputObj.firstname =this.params.data.firstname;
  inputObj.lastname = this.params.data.lastname;
  inputObj.phoneNumber = this.params.data.phoneNumber;
  inputObj.agencyName =this.params.data.agencyName;
  inputObj.roleId =this.params.data.roleId;
  this.adminSerive.blockUserByAdmin(inputObj)
  .subscribe((res)=>{
    console.log(res);
    if(res){  
      this.isBlock = false;
      this.adminSerive.raiseEventForUser();
    }
    return res;
  })
}
unBlockUser(){ /** trigger when click on yes button delete confirmation pop-up */
  this.isBlock = true;
  const inputObj:any={};
  inputObj.userId = this.params.data.userId;
  inputObj.blockUser = false;
  inputObj.email =this.params.data.email;
  inputObj.firstname =this.params.data.firstname;
  inputObj.lastname = this.params.data.lastname;
  inputObj.phoneNumber = this.params.data.phoneNumber;
  inputObj.agencyName =this.params.data.agencyName;
  inputObj.roleId =this.params.data.roleId;
  this.adminSerive.blockUserByAdmin(inputObj)
  .subscribe((res)=>{
    console.log(res);
    if(res){  
      this.isBlock = false;
      this.adminSerive.raiseEventForUser();
    }
    return res;
  })
}
shareDoc(){ /** trigger when click on share icon */

    this.sharedocumentsService.shareDocEvent(this.params.data);
  
}
  downloadDocs() {
    this.isDownload = true;
    this.sharedocumentsService.downloadDocument(this.params.data.caregiverDocId, "download").
      subscribe(response => {
        this.isDownload = false;
       this.isDownloaded =false;
        let resp: any = response;
        if (!resp.body || resp.body == null || resp.body == 'null') {
          alert('Permission got expired or no file available!');
          return;
        }
        let filename = '';
        let contentType = resp.headers.get('content-type');
        var disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }
       
        let blob: any = new Blob([resp.body], { type: contentType });
       
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        //window.location.href = response.url;
        fileSaver.saveAs(blob, filename.slice(11));
      /* if(this.isDownloaded){
        setTimeout(()=>{
          this.isDownloaded = false;
         },3000)    
       }*/
      }),
     
      error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
                
  }

}