import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent } from '../layout/main/main.component';
import { FaqComponent } from './faq/faq.component';
import { CaregiverComponent } from './for_caregiver/caregiver.component';
import { RecruiterComponent } from './for_recruiter/recruiter.component';
import { SecurityComponent } from './for_security/security.component';
import { HomeComponent } from './home/home.component';




const routes: Routes = [
   
       { path: '', component: HomeComponent, pathMatch: 'full'},
       { path: 'caregiver', component:CaregiverComponent, pathMatch: 'full'},
       { path: 'recruiter', component:RecruiterComponent},
       { path: 'security', component:SecurityComponent},
       { path: 'faq', component:FaqComponent},
];

@NgModule({
    imports: [ RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutRoutingModule {}
