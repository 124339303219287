<div class="updateProfDia">
    <!--<h1>Profile Settings</h1> -->
      <div class="sub-container-fluid">
        <!--profile photo and setting options -->
        
        <!-- personal profile for ADMIN-->
       
        <!-- personal profile for recruiter-->
        <div  class="editForms">
            <div class="sub-edit-container">
                <h4>Personal Profile</h4>
            <div class="formContainer">
            <form  [formGroup]="updateProfileForm" novalidate >
              <div class="ProfileForm">
                <div>               
                <mat-form-field appearance="outline"> 
                    <mat-label style="font-size: 17px;">First Name *</mat-label>
                    <input formControlName="firstName" matInput placeholder="first Name" autocomplete="off">
                    <mat-error *ngIf="!updateProfileForm.controls['firstName'].valid && updateProfileForm.controls['firstName'].touched">
                      FirstName field is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div>               
                  <mat-form-field appearance="outline"> 
                      <mat-label style="font-size: 17px;">Last Name *</mat-label>
                      <input formControlName="lastName" value="sam" matInput placeholder="last Name" autocomplete="off">
                      <mat-error *ngIf="!updateProfileForm.controls['lastName'].valid && updateProfileForm.controls['lastName'].touched">
                        LastName field is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                    <div style="display: flex;">
                      <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Email *</mat-label>
                        <input formControlName="username" matInput placeholder="Email" autocomplete="off">
                        <mat-error *ngIf="!updateProfileForm.controls['username'].valid && updateProfileForm.controls['username'].touched">
                          {{ getErrorEmail() }}
                        </mat-error>
                      </mat-form-field>
                      </div>
                      <div *ngIf="isEmailToken">
                        <mat-form-field   appearance="outline"> 
                          <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                          <input matInput formControlName="otcUserName" placeholder="Verification code" autocomplete="off">
                          <mat-error *ngIf="!updateProfileForm.controls['otcUserName'].valid && updateProfileForm.controls['otcUserName'].touched">
                            {{ getErrorOtc1() }}
                          </mat-error>
                          <!--<mat-error *ngIf="!updateProfileForm.controls['username'].valid && updateProfileForm.controls['username'].touched">
                            {{ getErrorEmail1() }}
                          </mat-error>-->
                        </mat-form-field>
                        </div>
                      <div>
                        <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar1">*</span></mat-label>
                        <input formControlName="phoneNumber" matInput placeholder="Enter new Phone Number" autocomplete="off">
                        <mat-error *ngIf="!updateProfileForm.controls['phoneNumber'].valid && updateProfileForm.controls['phoneNumber'].touched">
                          {{getErrorPhone()}}
                        </mat-error>
                      </mat-form-field>
                      </div>
                      <div *ngIf="isMobileToken">
                        <mat-form-field   appearance="outline"> 
                          <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                          <input matInput formControlName="otcPhoneNumber" placeholder="Verification code" autocomplete="off">
                          <mat-error *ngIf="!updateProfileForm.controls['otcPhoneNumber'].valid && updateProfileForm.controls['otcPhoneNumber'].touched">
                            {{ getErrorOtc2() }}
                          </mat-error>
                          <!--<mat-error *ngIf="!updateProfileForm.controls['username'].valid && updateProfileForm.controls['username'].touched">
                            {{ getErrorEmail1() }}
                          </mat-error>-->
                        </mat-form-field>
                        </div>
                <div *ngIf="isRecruiter && !isCaregiver">
                  <mat-form-field   appearance="outline"> 
                    <mat-label style="font-size: 17px;"> AgencyName *</mat-label>
                    <input formControlName="agencyName" matInput placeholder="Enter Agency name" autocomplete="off">
                    <mat-error *ngIf="!updateProfileForm.controls['agencyName'].valid && updateProfileForm.controls['agencyName'].touched">
                      AgencyName is required.
                     </mat-error>              
                  </mat-form-field>
                </div>
                <div *ngIf="!isRecruiter && isCaregiver">    
                    <mat-form-field   appearance="outline"> 
                    <mat-label style="font-size: 17px;"> Profession *</mat-label>
                    <mat-select formControlName="profession" matInput (selectionChange)="populateSpecialties($event)" placeholder="Choose Profession" autocomplete="off">
                      <mat-option>Choose Profession</mat-option>
                      <mat-option *ngFor="let profession of professions" [value]="profession"> {{profession.professionName}}</mat-option>
                    </mat-select>
                      <mat-error *ngIf="!updateProfileForm.controls['profession'].valid && updateProfileForm.controls['profession'].touched">
                        Profession is required.
                       </mat-error>
                  </mat-form-field>             
                </div>
                <div *ngIf="!isRecruiter && isCaregiver">  
                  <mat-form-field   appearance="outline"> 
                    <mat-label style="font-size: 17px;"> Speciality *</mat-label>
                    <mat-select formControlName="speciality" matInput placeholder="Choose Speciality" autocomplete="off">
                    <mat-option>Choose Speciality</mat-option>
                    <mat-option *ngFor="let speciality of specialities" [value] ="speciality">{{speciality.specialtyName}}</mat-option>
                  </mat-select>
                    <mat-error *ngIf="!updateProfileForm.controls['speciality'].valid && updateProfileForm.controls['speciality'].touched">
                      Speciality is required.
                     </mat-error>              
                  </mat-form-field>
              
                </div>
                <div style="margin-left:0px;margin-right:0px;text-align: center;">
                  <span style="color: #ffffff; padding-right:0.8em">Notification Preference: </span> 
                  <mat-checkbox (change)="radioButton($event)" formControlName="enableEmailNotif" value="email" ngDefaultControl [checked]="isEmail">Email</mat-checkbox>
                  <mat-checkbox (change)="radioButton($event)" formControlName="enableMobileNotif" value="sms" ngDefaultControl [checked]="isMobile">SMS</mat-checkbox>
                     
                      <ng-container *ngIf="isNoteOpen && isMobile">
              
                        <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px">
                          
                          NOTE : Standard text messaging rates from your wireless carrier may apply
                           
                        </div>
                
                      </ng-container>
                
                </div>
                <div class="submitButtons"> 
                  <button class="btn btn-primary" mat-button type="button" (click)="closeAll()">Cancel</button>
                  <!--<button *ngIf="!isloading"  class="btn btn-primary" mat-button type="submit" (click)="onProfileUpdate(updateProfileForm.value)"  [disabled]="!updateProfileForm.valid">Update</button>-->

                  <button *ngIf="!isloading && !isMobileToken && !isEmailToken"  class="btn btn-primary" mat-button type="submit" (click)="onProfileUpdate(updateProfileForm.value)"  [disabled]="!updateProfileForm.valid">Update</button>
                <button *ngIf="!isloading && (isMobileToken || isEmailToken)"  class="btn btn-primary" mat-button type="submit" (click)="onValidateAndUpdate(updateProfileForm.value)"  [disabled]="!updateProfileForm.valid">Update</button>
               
                    <button *ngIf="isloading" class="btn btn-primary" mat-button type="submit"><i class="fa fa-spinner"></i></button>
                </div>   
                </div>          
              </form>
              
              </div>
              </div>
        </div>
        <!-- personal profile for caregiver -->
       
      <!-- change phone number token -->
      <!--<div>
        <div class="fadeIn first loginHeading">
          <span>Validate Your phoneNumber</span>
         </div>
         <br/>
        <div class="container-fluid">
          <form [formGroup]="OTCForm" (ngSubmit)="cnfmphoneNumber(OTCForm.value)" novalidate>
            <p *ngIf="emailNotification">Verification code has been sent to <a style="color:#0f6cbc;"> {{activationEMail}}</a>.
            Please check your email and activate your account by validating the verification code.</p>
            <p  *ngIf="mobileNotification">Verification code has been sent to you via text message
             .Please check your text message and activate your account by validating the verification code.</p>
            <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
              <mat-label style="font-size: 17px;"> Enter Verification code </mat-label>
              <input matInput placeholder="Enter 4-digit verification code" formControlName="otc"   autocomplete="off">
              <mat-error *ngIf="!OTCForm.controls['otc'].valid && OTCForm.controls['otc'].touched">
                {{ getErrorOtc1() }}
              </mat-error>
          </mat-form-field>
          <button type="submit" class="btn btn-primary" [disabled]="!OTCForm.valid">Validate Account</button>
          <button *ngIf="isOtcLoading" class="btn btn-primary spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
          <p>  <a class="resendButton" (click)="resend()" >Click here</a> to resend verification code if you haven't received</p>
    
      </form>
      </div>  
        </div>-->
        <!-- change password -->
       
</div>
</div>

<ng-template #notifMessages>
  <h4 class="modal-title w-100">
    {{text}}
    </h4>
    <div style="text-align:center">
      <button type="button" class="btn btn-primary" style="background-color:#AA004D;color: white;outline: none;" mat-button (click)="closeDialog()">OK</button>
      </div>
  </ng-template>
  <ng-template #selectNotipre>
    <div>
      <h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>You should select at least one option from Email and SMS</h4>
      </div>
      <div style="text-align:center">
        <button style="outline:none;color:white;width:auto;padding: 5px 16px;" type="button" class="btn btn-primary" (click)="closeDialog()">OK</button>
        </div>
    </ng-template>