<div class="container-fluid profileScreen">
    <!--<h1>Profile Settings</h1> -->
      <div class="sub-container-fluid">
        <!--profile photo and setting options -->
        <div class="settings">
            <div>
            <!--<p style="text-align: center;background-color: #5aa9ee;
            color: white;
            padding: 25px;margin:0;font-size:20px">Profile</p>-->
            <div style="text-align: center;padding:0 0 20px;position: relative;color: #2777BA;">
              <h1 style="
              padding: 15px 0;
              color: #ffffff;
              background-color: #2777BA;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              font-family: 'Acumin Variable Concept';
              ">Profile Settings</h1> 
              <img class="imageContainer" style="height: 180px;
    width: 180px;
    border-radius: 50%;cursor: pointer;border: 1px solid #989898;" [src]="userImage" (click)="isOptionsOpen = !isOptionsOpen" *ngIf="isSelectImage">
        <input style="display:none" type="file" accept="image/*" (change)="onSelectFile($event)" #imageselect>
        <svg class="svgContainer" style="cursor: pointer;" (click)="imageselect.click()" *ngIf="!isSelectImage" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212 212" width="180" height="180">
          <path fill="#DFE5E7" class="background" d="M106.251.5C164.653.5 212 47.846 212 106.25S164.653 212 106.25 212C47.846 212 .5 164.654.5 106.25S47.846.5 106.251.5z">
          </path>
          <path fill="#FFF" class="primary" 
          d="M173.561 171.615a62.767 62.767 0 0 0-2.065-2.955 67.7 67.7 0 0 0-2.608-3.299 70.112 70.112 0 0 0-3.184-3.527 71.097 71.097 0 0 0-5.924-5.47 72.458 72.458 0 0 0-10.204-7.026 75.2 75.2 0 0 0-5.98-3.055c-.062-.028-.118-.059-.18-.087-9.792-4.44-22.106-7.529-37.416-7.529s-27.624 3.089-37.416 7.529c-.338.153-.653.318-.985.474a75.37 75.37 0 0 0-6.229 3.298 72.589 72.589 0 0 0-9.15 6.395 71.243 71.243 0 0 0-5.924 5.47 70.064 70.064 0 0 0-3.184 3.527 67.142 67.142 0 0 0-2.609 3.299 63.292 63.292 0 0 0-2.065 2.955 56.33 56.33 0 0 0-1.447 2.324c-.033.056-.073.119-.104.174a47.92 47.92 0 0 0-1.07 1.926c-.559 1.068-.818 1.678-.818 1.678v.398c18.285 17.927 43.322 28.985 70.945 28.985 27.678 0 52.761-11.103 71.055-29.095v-.289s-.619-1.45-1.992-3.778a58.346 58.346 0 0 0-1.446-2.322zM106.002 125.5c2.645 0 5.212-.253 7.68-.737a38.272 38.272 0 0 0 3.624-.896 37.124 37.124 0 0 0 5.12-1.958 36.307 36.307 0 0 0 6.15-3.67 35.923 35.923 0 0 0 9.489-10.48 36.558 36.558 0 0 0 2.422-4.84 37.051 37.051 0 0 0 1.716-5.25c.299-1.208.542-2.443.725-3.701.275-1.887.417-3.827.417-5.811s-.142-3.925-.417-5.811a38.734 38.734 0 0 0-1.215-5.494 36.68 36.68 0 0 0-3.648-8.298 35.923 35.923 0 0 0-9.489-10.48 36.347 36.347 0 0 0-6.15-3.67 37.124 37.124 0 0 0-5.12-1.958 37.67 37.67 0 0 0-3.624-.896 39.875 39.875 0 0 0-7.68-.737c-21.162 0-37.345 16.183-37.345 37.345 0 21.159 16.183 37.342 37.345 37.342z">
        </path></svg>
        <p style="margin: 10px 0 0 0;
        font-size: 16px;font-family: 'Century Gothic';font-weight: bold;">{{fullName}}</p>
         <ul *ngIf="isOptionsOpen" style=" position: absolute;
         top: 40%;
         right: 5%;
         padding: 0px;
         list-style-type: none;
         background-color: #ffffff78;
         border-radius: 2px;
         border:1px solid rgb(216, 214, 214);
         text-align: left;
         font-family: 'Century Gothic';
         ">
          <li (click)="viewPhoto()"><a >View Photo</a></li>
          <li (click)="imageselect.click();isOptionsOpen = false"><a>Change Photo</a></li>
        </ul>

        <div class="mouseOver">
          <i style="display: block;" class="fa fa-camera"></i>
          <span>CHANGE PROFILE PHOTO</span>
        </div>       
              </div>
              <ng-template #imageView>
              <div>
                <div style="text-align: right;
                font-size: 20px;">
                  <span title="close" style="cursor: pointer;" mat-dialog-close>&times;</span>
                  </div>
                <img class="imageContainer" style="height: 400px;
    width: 400px;" [src]="userImage">
                </div>
                </ng-template>
            <ul style="list-style-type: none;padding:0;margin: 0;font-family: 'Century Gothic';">
                <li (click)="openProfile()" id="profile"
                style="cursor: pointer;padding: 15px 30px;margin:5px;border-radius: 5px;border: 2px double #2777BA;">
                    Personal Profile<i style="float: right;font-size: 20px;" class="fa fa-edit" ></i>
                </li>
                <li (click)="openChangePwd()" id="password"
                style="padding: 15px 30px;cursor: pointer;margin:5px;border-radius: 5px;border: 2px double #2777BA;">
                    Change Password<i style="float: right;font-size: 20px;" class="fa fa-edit" ></i>
                </li>
            </ul>
        </div>
        </div>
        <!-- personal profile for ADMIN-->
        <div *ngIf="isProfilOpen && !isRecruiter && !isCaregiver && isAdmin " class="editForms">
          <div class="sub-edit-container">
              <h4>Personal Profile</h4>
          <div class="formContainer">
          <form  [formGroup]="updateAdminForm" novalidate >
            <div>
              <div>               
              <mat-form-field appearance="outline"> 
                  <mat-label style="font-size: 17px;">First Name *</mat-label>
                  <input formControlName="firstName" matInput placeholder="first Name" autocomplete="off">
                  <mat-error *ngIf="!updateAdminForm.controls['firstName'].valid && updateAdminForm.controls['firstName'].touched">
                    FirstName field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div>               
                <mat-form-field appearance="outline"> 
                    <mat-label style="font-size: 17px;">Last Name *</mat-label>
                    <input formControlName="lastName" value="sam" matInput placeholder="last Name" autocomplete="off">
                    <mat-error *ngIf="!updateAdminForm.controls['lastName'].valid && updateAdminForm.controls['lastName'].touched">
                      LastName field is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                  <div style="display: flex;">
                    <mat-form-field   appearance="outline"> 
                      <mat-label style="font-size: 17px;"> Email *</mat-label>
                      <input formControlName="username" matInput placeholder="Email" autocomplete="off">
                      <mat-error *ngIf="!updateAdminForm.controls['username'].valid && updateAdminForm.controls['username'].touched">
                        {{ getErrorEmail() }}
                      </mat-error>
                    </mat-form-field>
                    </div>
                    <div *ngIf="isEmailToken">
                      <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                        <input matInput formControlName="otcUserName" placeholder="Verification code" autocomplete="off">
                        <mat-error *ngIf="!updateAdminForm.controls['otcUserName'].valid && updateAdminForm.controls['otcUserName'].touched">
                          {{ getErrorOtc3() }}
                        </mat-error>
                        <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                          {{ getErrorEmail1() }}
                        </mat-error>-->
                      </mat-form-field>
                      </div>
                    <div>
                      <mat-form-field   appearance="outline"> 
                      <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar1">*</span></mat-label>
                      <input formControlName="phoneNumber" matInput placeholder="Enter new Phone Number" autocomplete="off">
                      <mat-error *ngIf="!updateAdminForm.controls['phoneNumber'].valid && updateAdminForm.controls['phoneNumber'].touched">
                        {{getErrorPhone()}}
                      </mat-error>
                    </mat-form-field>
                    </div>
                    <div *ngIf="isMobileToken">
                      <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                        <input matInput formControlName="otcPhoneNumber" placeholder="Verification code" autocomplete="off">
                        <mat-error *ngIf="!updateAdminForm.controls['otcPhoneNumber'].valid && updateAdminForm.controls['otcPhoneNumber'].touched">
                          {{ getErrorOtc4() }}
                        </mat-error>
                        <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                          {{ getErrorEmail1() }}
                        </mat-error>-->
                      </mat-form-field>
                      </div>
              <div style="margin-left:0px;margin-right:0px;text-align: center;">
                <span style="color: #ffffff; padding-right:0.8em">Notification Preference: </span> 
                <mat-checkbox (change)="radioButton($event)" formControlName="enableEmailNotif" value="email" ngDefaultControl [checked]="isEmail">Email</mat-checkbox>
                <mat-checkbox (change)="radioButton($event)" formControlName="enableMobileNotif" value="sms" ngDefaultControl [checked]="isMobile">SMS</mat-checkbox>
                   
                    <ng-container *ngIf="isNoteOpen && isMobile">
            
                      <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px">
                        
                        NOTE : Standard text messaging rates from your wireless carrier may apply
                         
                      </div>
              
                    </ng-container>
              
              </div>
              <div class="submitButtons"> 
                <button *ngIf="!isloading && !isMobileToken && !isEmailToken"  class="btn btn-primary" mat-button type="submit" (click)="onProfileUpdate(updateAdminForm.value, updateAdminForm.valid)"  [disabled]="!updateAdminForm.valid">Update</button>
                <button *ngIf="!isloading && (isMobileToken || isEmailToken)"  class="btn btn-primary" mat-button type="submit" (click)="onValidateAndUpdate(updateAdminForm.value, updateAdminForm.valid)"  [disabled]="!updateAdminForm.valid">Update</button>
                  <button *ngIf="isloading" class="btn btn-primary" mat-button type="submit"><i class="fa fa-spinner"></i></button>
                <button class="btn btn-primary" mat-button type="button" (click)="closeAll()">Cancel</button>
              </div>   
              </div>          
            </form>
            
            </div>
            </div>
      </div>
        <!-- personal profile for recruiter-->
        <div *ngIf="isProfilOpen && isRecruiter && !isCaregiver " class="editForms">
            <div class="sub-edit-container">
                <h4>Personal Profile</h4>
            <div class="formContainer">
            <form  [formGroup]="updateRecruiterForm" novalidate >
              <div>
                <div>               
                <mat-form-field appearance="outline"> 
                    <mat-label style="font-size: 17px;">First Name *</mat-label>
                    <input formControlName="firstName" matInput placeholder="first Name" autocomplete="off">
                    <mat-error *ngIf="!updateRecruiterForm.controls['firstName'].valid && updateRecruiterForm.controls['firstName'].touched">
                      FirstName field is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div>               
                  <mat-form-field appearance="outline"> 
                      <mat-label style="font-size: 17px;">Last Name *</mat-label>
                      <input formControlName="lastName" value="sam" matInput placeholder="last Name" autocomplete="off">
                      <mat-error *ngIf="!updateRecruiterForm.controls['lastName'].valid && updateRecruiterForm.controls['lastName'].touched">
                        LastName field is required.
                      </mat-error>
                    </mat-form-field>
                  </div>
                    <div style="display: flex;">
                      <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Email *</mat-label>
                        <input formControlName="username" matInput placeholder="Email" autocomplete="off">
                        <mat-error *ngIf="!updateRecruiterForm.controls['username'].valid && updateRecruiterForm.controls['username'].touched">
                          {{ getErrorEmail() }}
                        </mat-error>
                      </mat-form-field>
                      </div>
                      <div *ngIf="isEmailToken">
                        <mat-form-field   appearance="outline"> 
                          <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                          <input matInput formControlName="otcUserName" placeholder="Verification code" autocomplete="off">
                          <mat-error *ngIf="!updateRecruiterForm.controls['otcUserName'].valid && updateRecruiterForm.controls['otcUserName'].touched">
                            {{ getErrorOtc3() }}
                          </mat-error>
                          <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                            {{ getErrorEmail1() }}
                          </mat-error>-->
                        </mat-form-field>
                        </div>
                      <div>
                        <mat-form-field   appearance="outline"> 
                        <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar1">*</span></mat-label>
                        <input formControlName="phoneNumber" matInput placeholder="Enter new Phone Number" autocomplete="off">
                        <mat-error *ngIf="!updateRecruiterForm.controls['phoneNumber'].valid && updateRecruiterForm.controls['phoneNumber'].touched">
                          {{getErrorPhone()}}
                        </mat-error>
                      </mat-form-field>
                      </div>
                      <div *ngIf="isMobileToken">
                        <mat-form-field   appearance="outline"> 
                          <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                          <input matInput formControlName="otcPhoneNumber" placeholder="Verification code" autocomplete="off">
                          <mat-error *ngIf="!updateRecruiterForm.controls['otcPhoneNumber'].valid && updateRecruiterForm.controls['otcPhoneNumber'].touched">
                            {{ getErrorOtc4() }}
                          </mat-error>
                          <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                            {{ getErrorEmail1() }}
                          </mat-error>-->
                        </mat-form-field>
                        </div>
                <div>
                  <mat-form-field   appearance="outline"> 
                    <mat-label style="font-size: 17px;"> AgencyName *</mat-label>
                    <input formControlName="agencyName" matInput placeholder="Enter Agency name" autocomplete="off">
                    <mat-error *ngIf="!updateRecruiterForm.controls['agencyName'].valid && updateRecruiterForm.controls['agencyName'].touched">
                      AgencyName is required.
                     </mat-error>              
                  </mat-form-field>
                </div>
                <div style="margin-left:0px;margin-right:0px;text-align: center;">
                  <span style="color: #ffffff; padding-right:0.8em">Notification Preference: </span> 
                  <mat-checkbox (change)="radioButton($event)" formControlName="enableEmailNotif" value="email" ngDefaultControl [checked]="isEmail">Email</mat-checkbox>
                  <mat-checkbox (change)="radioButton($event)" formControlName="enableMobileNotif" value="sms" ngDefaultControl [checked]="isMobile">SMS</mat-checkbox>
                     
                      <ng-container *ngIf="isNoteOpen && isMobile">
              
                        <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px">
                          
                          NOTE : Standard text messaging rates from your wireless carrier may apply
                           
                        </div>
                
                      </ng-container>
                
                </div>
                <div class="submitButtons"> 
                  <button *ngIf="!isloading && !isMobileToken && !isEmailToken"  class="btn btn-primary" mat-button type="submit" (click)="onProfileUpdate(updateRecruiterForm.value, updateRecruiterForm.valid)"  [disabled]="!updateRecruiterForm.valid">Update</button>
                  <button *ngIf="!isloading && (isMobileToken || isEmailToken)"  class="btn btn-primary" mat-button type="submit" (click)="onValidateAndUpdate(updateRecruiterForm.value, updateRecruiterForm.valid)"  [disabled]="!updateRecruiterForm.valid">Update</button>
                    <button *ngIf="isloading" class="btn btn-primary" mat-button type="submit"><i class="fa fa-spinner"></i></button>
                  <button class="btn btn-primary" mat-button type="button" (click)="closeAll()">Cancel</button>
                </div>   
                </div>          
              </form>
              
              </div>
              </div>
        </div>
        <!-- personal profile for caregiver -->
        <div *ngIf="isProfilOpen && !isRecruiter && isCaregiver" class="editForms">
          <div class="sub-edit-container">
              <h4>Personal Profile</h4>
          <div class="formContainer">
          <form  [formGroup]="updateCaregiverForm" novalidate >
              <div>               
                <mat-form-field appearance="outline"> 
                  <mat-label style="font-size: 17px;">First Name *</mat-label>
                  <input formControlName="firstName" matInput placeholder="First Name" autocomplete="off">
                  <mat-error *ngIf="!updateCaregiverForm.controls['firstName'].valid && updateCaregiverForm.controls['firstName'].touched">
                    FirstName field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div>               
                <mat-form-field appearance="outline"> 
                  <mat-label style="font-size: 17px;">Last Name *</mat-label>
                  <input formControlName="lastName" value="sam" matInput placeholder="Last Name" autocomplete="off">
                  <mat-error *ngIf="!updateCaregiverForm.controls['lastName'].valid && updateCaregiverForm.controls['lastName'].touched">
                    LastName field is required.
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field   appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Email *</mat-label>
                  <input formControlName="username" matInput placeholder="Email" autocomplete="off">
                  <mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                    {{ getErrorEmail1() }}
                  </mat-error>
                </mat-form-field>
               
                </div>
                <div *ngIf="isEmailToken">
                  <mat-form-field   appearance="outline"> 
                    <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                    <input matInput formControlName="otcUserName" placeholder="Verification code" autocomplete="off">
                    <mat-error *ngIf="!updateCaregiverForm.controls['otcUserName'].valid && updateCaregiverForm.controls['otcUserName'].touched">
                      {{ getErrorOtc1() }}
                    </mat-error>
                    <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                      {{ getErrorEmail1() }}
                    </mat-error>-->
                  </mat-form-field>
                  </div>
                <div>
                  <mat-form-field   appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Phone Number <span *ngIf="isEnableStar">*</span></mat-label>
                  <input formControlName="phoneNumber" matInput placeholder="Phone Number" autocomplete="off">
                  <mat-error *ngIf="!updateCaregiverForm.controls['phoneNumber'].valid && updateCaregiverForm.controls['phoneNumber'].touched">
                    {{getErrorPhone1()}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="isMobileToken">
                <mat-form-field   appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
                  <input matInput formControlName="otcPhoneNumber" placeholder="Verification code" autocomplete="off">
                  <mat-error *ngIf="!updateCaregiverForm.controls['otcPhoneNumber'].valid && updateCaregiverForm.controls['otcPhoneNumber'].touched">
                    {{ getErrorOtc2() }}
                  </mat-error>
                  <!--<mat-error *ngIf="!updateCaregiverForm.controls['username'].valid && updateCaregiverForm.controls['username'].touched">
                    {{ getErrorEmail1() }}
                  </mat-error>-->
                </mat-form-field>
                </div>
                  <div>     
                  <mat-form-field   appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Profession *</mat-label>
                  <mat-select formControlName="profession" matInput (selectionChange)="populateSpecialties($event)" placeholder="Choose Profession" autocomplete="off">
                    <mat-option>Choose Profession</mat-option>
                    <mat-option *ngFor="let profession of professions" [value]="profession"> {{profession.professionName}}</mat-option>
                  </mat-select>
                    <mat-error *ngIf="!updateCaregiverForm.controls['profession'].valid && updateCaregiverForm.controls['profession'].touched">
                      Profession is required.
                     </mat-error>
                </mat-form-field>             
              </div>
              <div>     
                <mat-form-field   appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Speciality *</mat-label>
                  <mat-select formControlName="speciality" matInput placeholder="Choose Speciality" autocomplete="off">
                  <mat-option>Choose Speciality</mat-option>
                  <mat-option *ngFor="let speciality of specialities" [value] ="speciality">{{speciality.specialtyName}}</mat-option>
                </mat-select>
                  <mat-error *ngIf="!updateCaregiverForm.controls['speciality'].valid && updateCaregiverForm.controls['speciality'].touched">
                    Speciality is required.
                   </mat-error>              
                </mat-form-field>
            
              </div>
              <div style="margin-left:0px;margin-right:0px;text-align: center;">
                <span style="color: #ffffff; padding-right:0.8em">Notification Preference: </span> 
                <mat-checkbox (change)="radioButton($event)" formControlName="enableEmailNotif" value="email" [checked]="isEmail">Email</mat-checkbox>
                <mat-checkbox (change)="radioButton($event)" formControlName="enableMobileNotif" value="sms" [checked]="isMobile">SMS</mat-checkbox>
            
                    <ng-container *ngIf="isNoteOpen && isMobile">
            
                      <div style="padding:1em; text-align: left; color:#ffffff; margin:5px 0px">
                        
                        NOTE : Standard text messaging rates from your wireless carrier may apply
                         
                      </div>
              
                    </ng-container>
              
              </div>
              <div class="submitButtons"> 
                <button *ngIf="!isloading && !isMobileToken && !isEmailToken"  class="btn btn-primary" mat-button type="submit" (click)="onProfileUpdate(updateCaregiverForm.value, updateCaregiverForm.valid)"  [disabled]="!updateCaregiverForm.valid">Update</button>
                <button *ngIf="!isloading && (isMobileToken || isEmailToken)"  class="btn btn-primary" mat-button type="submit" (click)="onValidateAndUpdate(updateCaregiverForm.value, updateCaregiverForm.valid)"  [disabled]="!updateCaregiverForm.valid">Update</button>
                <button *ngIf="isloading" class="btn btn-primary" mat-button type="submit"><i class="fa fa-spinner"></i></button>
                <button class="btn btn-primary" mat-button type="button" (click)="closeAll()">Cancel</button>
              </div>             
            </form>
            
            </div>
            </div>
      </div>
      <!-- change phone number token -->
      <!--<div>
        <div class="fadeIn first loginHeading">
          <span>Validate Your phoneNumber</span>
         </div>
         <br/>
        <div class="container-fluid">
          <form [formGroup]="OTCForm" (ngSubmit)="cnfmphoneNumber(OTCForm.value)" novalidate>
            <p *ngIf="emailNotification">Verification code has been sent to <a style="color:#0f6cbc;"> {{activationEMail}}</a>.
            Please check your email and activate your account by validating the verification code.</p>
            <p  *ngIf="mobileNotification">Verification code has been sent to you via text message
             .Please check your text message and activate your account by validating the verification code.</p>
            <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
              <mat-label style="font-size: 17px;"> Enter Verification code </mat-label>
              <input matInput placeholder="Enter 4-digit verification code" formControlName="otc"   autocomplete="off">
              <mat-error *ngIf="!OTCForm.controls['otc'].valid && OTCForm.controls['otc'].touched">
                {{ getErrorOtc1() }}
              </mat-error>
          </mat-form-field>
          <button type="submit" class="btn btn-primary" [disabled]="!OTCForm.valid">Validate Account</button>
          <button *ngIf="isOtcLoading" class="btn btn-primary spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
          <p>  <a class="resendButton" (click)="resend()" >Click here</a> to resend verification code if you haven't received</p>
    
      </form>
      </div>  
        </div>-->
        <!-- change password -->
        <div *ngIf="isChangePwdOpen"  class="editForms">
          <div class="sub-edit-container">
            <h4>Change Password</h4>
            <div *ngIf="!otcMessageEmail && !otcMessageMobile" style="font-size: 16px;
            color: #ffffff;
            font-family: 'Century Gothic';">
            <p>Need to change your current password?No problem.</p>
            <p>Just <a style="color:#AA004D ;text-decoration:underline;cursor: pointer;" (click)="generateOtc()">Click here </a> to get verification code.</p>
            </div>
            <div  style="font-size: 16px;
            color: #ffffff;
           font-family: 'Century Gothic';"
            *ngIf="otcMessageEmail && otcMessageMobile">
              <p>Verification code has been sent to <a style="color:#ffffff;"> {{' '+userName}}</a> and <a style="color:#ffffff;">{{' ' +phoneNumber}}</a>.
                Please check your email/text message and change your password.</p>
              </div>
            <div  style="font-size: 16px;
            color: #302828;
            font-family: 'Century Gothic';" 
            *ngIf="otcMessageEmail && !otcMessageMobile">
              <p>Verification code has been sent to <a style="color:#ffffff;"> {{' '+userName}}</a>.
                Please check your email and change your password.</p>
              </div>
              <div  style="font-size: 16px;
              color: #ffffff;
              font-family: 'Century Gothic';"
               *ngIf="!otcMessageEmail && otcMessageMobile">
                <p>Verification code has been sent to <a style="color:#ffffff;">{{' ' +phoneNumber}}</a>.
    Please check your text message and change your password.</p>       
                 </div>
        <div>
        <form [formGroup]="updatePassword" (ngSubmit)="onPasswordUpdate(updatePassword.value)">
          <div>               
          <mat-form-field class="matFormField" appearance="outline"> 
              <mat-label style="font-size: 17px;"> Verification Code *</mat-label>
              <input  formControlName="smsToken" matInput placeholder="Enter 4-digit Verification Code" autocomplete="off">
              <mat-error *ngIf="!updatePassword.controls['smsToken'].valid && updatePassword.controls['smsToken'].touched">
                {{ getErrorOtc() }}
              </mat-error>
            </mat-form-field>
             <button type="button" *ngIf="!isResendLoading" class="btn btn-primary" style="margin-bottom: 10px;width:80px;
             margin-left: 10px;background-color: #AA004D !important;
    border-color: #AA004D !important;
    font-family: 'Century Gothic';" (click)="generateResendOtc()" [disabled] ="!otcMessageEmail && !otcMessageMobile">Resend</button>
              <button *ngIf="isResendLoading" class="btn btn-primary" style="margin-bottom: 10px;width:80px;
              margin-left: 10px;background-color: #AA004D !important;
    border-color: #AA004D !important;"><i class="fa fa-spinner"></i></button>
 
          </div>
          <div formGroupName ="passwordGroup">
          <div id="passwordFormat">               
            <mat-form-field appearance="outline" > 
                <mat-label style="font-size: 17px;"> New Password *</mat-label>
                <input matInput formControlName="password"  [type]="hidepassword1 ? 'text' : 'password'" placeholder="Enter New Password"  autocomplete="off">
                <mat-icon style="color:#535353;font-size: 18px;" matSuffix (click)="hidepassword1 = !hidepassword1">{{hidepassword1 ? 'visibility ' : 'visibility_off'}}</mat-icon> 
                  <mat-error *ngIf="updatePassword.controls['passwordGroup'].get('password').dirty" >
                    {{errors.PasswordFormat}}                 </mat-error>
                 <mat-error *ngIf="!updatePassword.controls['passwordGroup'].get('password').dirty && updatePassword.controls['passwordGroup'].get('password').touched && !updatePassword.controls['passwordGroup'].get('password').valid" >
                  {{errors.Password}}
                </mat-error>
              </mat-form-field>
            </div>
            <div>               
              <mat-form-field appearance="outline"> 
                  <mat-label style="font-size: 17px;"> Confirm Password *</mat-label>
                  <input formControlName="confirmPassword" matInput [type]="hidepassword2 ? 'text' : 'password'" placeholder="Enter Confirm Password"  autocomplete="off" [errorStateMatcher]="matcher">
                  <mat-icon style="color:#535353;font-size: 18px;" matSuffix (click)="hidepassword2 = !hidepassword2">{{hidepassword2 ? 'visibility ' : 'visibility_off'}}</mat-icon> 
                    <mat-error *ngIf="updatePassword.controls['passwordGroup'].get('confirmPassword').dirty" >
                      {{errors.ConfirmPassword}}
                   </mat-error>
                   <mat-error *ngIf="!updatePassword.controls['passwordGroup'].get('confirmPassword').dirty && updatePassword.controls['passwordGroup'].get('confirmPassword').touched && !updatePassword.controls['passwordGroup'].get('confirmPassword').valid" >
                    {{errors.cnfmPassword}}
                  </mat-error>
                </mat-form-field>
              </div>
              </div>
              <div class="submitButtons"> 
                <button *ngIf="!isPwdloading" class="btn btn-primary" mat-button type="submit" [disabled]="!updatePassword.valid">Update</button>
                <button *ngIf="isPwdloading" class="btn btn-primary" mat-button ><i class="fa fa-spinner"></i></button>
                <button  class="btn btn-primary" type="button" mat-button (click)="closeChangePwd()">Cancel</button>
              </div>  
        </form>       
        </div>
        <div style="font-family: 'Century Gothic';">
          <p><b>Passwords must contain:</b></p>
          <ol>
            <li>
              Minimum of 8 characters
            </li>
            <li>
              one upper case, lower case letter, one special character(!, @, #, $, %, ^, &, *) and one number
            </li>
          </ol>
        </div>
          </div>
        </div>
       
</div>
</div>
<ng-template #redirectToLogin>
  <div>
  <h4 class="modal-title w-100">You need to login again with new email/username for confirmation.</h4>	
  </div>
  <div style="text-align:center">
    <button style="outline:none;" type="button" class="btn btn-primary" style="background-color:#AA004D;color: white;outline: none;" (click)="redirectsToLogin()" mat-button mat-dialog-close>OK</button>
    </div>
</ng-template>
<ng-template #reSentOtc>
  <h4 class="modal-title w-100">
    {{text}}
    </h4>
    <div style="text-align:center">
      <button type="button" class="btn btn-primary" style="background-color:#AA004D;color: white;outline: none;" mat-button mat-dialog-close>OK</button>
      </div>
  </ng-template>
  <ng-template #selectNotipre>
    <div>
      <h4 class="modal-title w-100"><i class="fa fa-exclamation-circle" style="margin-right:10px;cursor: auto;"></i>You should select at least one option from Email and SMS</h4>
      </div>
      <div style="text-align:center">
        <button style="outline:none;color:white;width:auto;padding: 5px 16px;" type="button" class="btn btn-primary" mat-dialog-close>OK</button>
        </div>
    </ng-template>