import { EventEmitter, Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import * as constants from '../shared-services/auth.constant';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public storage: any = {};
  static AUTH_TOKEN_URL = constants.API_BASE_URL + '/oauth/token';
  static GOOGLE_LOGIN_TOKEN_URL = constants.API_BASE_URL + '/login/google';
  static VALIDATE_2FA_ANSWERS_URL = constants.API_BASE_URL + '/api/private/authentication/validate2FAAnswers';
  static USER_REGISTRATION_URL = constants.API_BASE_URL + '/api/public/user/registration';
  static USER_OTCVALIDATION_URL = constants.API_BASE_URL + '/api/public/user/validateOTPToken';
  static USER_ACTIVATION_URL = constants.API_BASE_URL + '/api/public/user/activation';
  static USER_VALIDATION = constants.API_BASE_URL + '/api/public/user/validateUsername';
  static VALIDATE_FORGOTPWD_TOKEN = constants.API_BASE_URL + '/api/public/user/validateForgotPwdToken';
  static UPDATE_PASSWORD = constants.API_BASE_URL + '/api/public/user/updateUserPassword';
  static CHANGE_PASSWORD = constants.API_BASE_URL +'/api/private/profile/updatePassword';
  static GENERATE_CHANGE_PWD_TOKEN = constants.API_BASE_URL + '/api/private/profile/generateChangePwdToken';
  static INVITE_REGISTRATION_URL =constants.API_BASE_URL + '/api/public/user/inviteRegistration';
  static GET_USER_INVITATION_URL = constants.API_BASE_URL + '/api/public/user/acceptInvite';
  static USER_RESET_PWD_URL = constants.API_BASE_URL + '/api/public/user/resetPassword';
  static SECURITY_QUESTIONS_URL = constants.API_BASE_URL + '/api/private/users/getAllSecurityQuestions';
  static SAVE_SECURITY_QUESTIONS = constants.API_BASE_URL + '/api/private/users/saveSecurityQuestions';
  static VALIDATE_USER_NAME_URL =  constants.API_BASE_URL + '/api/public/reset_password/validateUsername';
  static VALIDATE_SECURITY_ANSWER_URL = constants.API_BASE_URL + '/api/public/reset_password/validateSecurityAnswer';
  static VALIDATE_TEMP_PASSWORD_URL = constants.API_BASE_URL + '/api/public/reset_password/validateTempPswd';
  static VALIDATE_TEMP_PASSWORD_FOR_CHANGE_PASSWORD_URL = constants.API_BASE_URL + '/api/public/change_password/validateTempPswdDuringLogin';
  static documentData = constants.API_BASE_URL + '/api/private/caregiver/documentNames';
  public loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
  public caregiverLogin = new BehaviorSubject<boolean>(this.caregiverCheck());
  public recruiterLogin = new BehaviorSubject<boolean>(this.recruiterCheck());
  public adminLogin = new BehaviorSubject<boolean>(this.adminCheck());
  public UserName = new BehaviorSubject<string>(localStorage.getItem("username"));
  profileImage:EventEmitter<any>;

  public userDetails;
    constructor(private httpClient: HttpClient) {
      this.profileImage = new EventEmitter<any>();
      if(localStorage.getItem('jwt') !=null){
        sessionStorage.setItem("access_token", localStorage.getItem('jwt'));
      }
     }
     raiseProfileEvent(){
       this.profileImage.emit();
     }
    private handleError(errorResponse: HttpErrorResponse){
      if(errorResponse.error instanceof ErrorEvent){
     // console.error('Client Side Error: ', errorResponse.error.message);
      } else {
     // console.error('Server Side Error: ', errorResponse);
      }
       
       return throwError(errorResponse);
      }
  login(username: string, password: string) {
   // const body = `username=${encodeURIComponent(username.toLowerCase())}&password=${encodeURIComponent(password)}&grant_type=password&login_from=`;
   const body = `username=${encodeURIComponent(username.trim())}&password=${encodeURIComponent(password)}&grant_type=password&login_from=`;
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/x-www-form-urlencoded')
    .append('Authorization', 'Basic ' + btoa(constants.TOKEN_AUTH_USERNAME + ':' + constants.TOKEN_AUTH_PASSWORD));
  //  console.log('login headers', headers);
    return this.httpClient.post(AuthenticationService.AUTH_TOKEN_URL, body, {headers:headers})
        .pipe(map((res: any) => {
          if (res.access_token) {
            
            localStorage.setItem('loginStatus', '1');
           // localStorage.setItem('jwt', res.access_token);
            localStorage.setItem("username", res.username);
           // return res.access_token;
          }
        //  return null;
        //this.loginStatus.next(true);
        return res;
        }));
}

getDocumentNames() {
  let authToken = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + authToken);
  
  return this.httpClient.get(AuthenticationService.documentData, {
       headers:headers, observe:'response'
    })
      .pipe(map((res: any) => {
        if (res) {
         // console.log("response "+JSON.stringify(res));
          //console.log('sam');
          return res;
        }
        return null;
      }),
      catchError(this.handleError)
      );
}
logout() {
  this.loginStatus.next(false);
  localStorage.removeItem('jwt');
  localStorage.removeItem('remember');
  localStorage.setItem("loginStatus", '0');
  this.caregiverLogin.next(false);
  this.recruiterLogin.next(false);
  this.adminLogin.next(false);
  
}
validateusername(token:string, user:any){
 
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
  const httpParams = new HttpParams()
  .set('username', user.username);
  return this.httpClient.get(AuthenticationService.USER_VALIDATION, {
    headers:headers,
    params:httpParams

  })
  .pipe(map((res: any) => {
    if (res) {
        return res;
    }
    return null;
}));
}
validateForgotpwdToken(token:string, user:any){
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
  const httpParams = new HttpParams()
  .set('username', user.username )
  .set('token', user.token);
  return this.httpClient.get(AuthenticationService.VALIDATE_FORGOTPWD_TOKEN, {
    headers:headers,
    params:httpParams})
  .pipe(map((res: any) => {
    if (res) {
        return res;
    }
    return null;
}));

}
updatePassword(token:string, user:any){
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Bearer ' + token);
  const httpParams = new HttpParams()
  .set('username', user.username )
  .set('password', user.password);
  return this.httpClient.get(AuthenticationService.UPDATE_PASSWORD, {
    headers:headers,
    params:httpParams
  })
  .pipe(map((res: any) => {
    if (res) {
        return res;
    }
    return null;
}));
}
generateChangePwdToken(){
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
  return this.httpClient.get(AuthenticationService.GENERATE_CHANGE_PWD_TOKEN, {headers:headers})
  .pipe(map((res)=>{
    return res;
  }))
}
changePassword(passwordObject){ /** change passowrd in profile settings */
  let token = localStorage.getItem('jwt');
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
  return this.httpClient.post(AuthenticationService.CHANGE_PASSWORD, passwordObject, {headers:headers})
  .pipe(map((res: any) => {
    if (res) {
        return res;
    }
    return null;
}));
}
validateUserName(token: string, user: any) {

  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);

  const httpParams = new HttpParams()
      .set('username', user.username.trim());

  return this.httpClient.get(AuthenticationService.VALIDATE_USER_NAME_URL, {
      headers: headers,
      params: httpParams
  })
      .pipe(map((res: any) => {
          if (res) {
              return res;
          }
          return null;
      }));
}

resetPassword(token:string, user:any){
const headers = new HttpHeaders()
.append('Content-Type', 'application/json')
.append('Authorization', 'Bearer ' + token);
return this.httpClient.put(AuthenticationService.USER_RESET_PWD_URL, user, {headers:headers})
.pipe(map((res: any) => {
// console.log('user '+ user);
    return res
  }));
}
reset() {
  this.storage = {};
}

loginWithGoogle(apitoken: string) {
  const body = `googleIdToken=${encodeURIComponent(apitoken)}&grant_type=password`;
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + btoa(constants.TOKEN_AUTH_USERNAME + ':' + constants.TOKEN_AUTH_PASSWORD));
 // console.log(' google login headers', headers);
  return this.httpClient.post(AuthenticationService.AUTH_TOKEN_URL, body, {headers:headers})
      .pipe(map((res: any) => {
        if (res.access_token) {
          localStorage.setItem('loginStatus', '1');
          localStorage.setItem('jwt', res.access_token);
          localStorage.setItem("username", res.username);
          // return res.access_token;
          return res;
        }
        return null;
      }));
}

loginWithFacebook(username: string) {
  const body = `facebookUserId=${encodeURIComponent(username)}&grant_type=password&Provider=FACEBOOK`;
  const headers = new HttpHeaders()
  .append('Content-Type', 'application/x-www-form-urlencoded')
  .append('Authorization', 'Basic ' + btoa(constants.TOKEN_AUTH_USERNAME + ':' + constants.TOKEN_AUTH_PASSWORD));
 // console.log('login headers', headers);
  return this.httpClient.post(AuthenticationService.AUTH_TOKEN_URL, body, {headers:headers})
      .pipe(map((res: any) => {
        if (res.access_token) {
          localStorage.setItem('loginStatus', '1');
          localStorage.setItem('jwt', res.access_token);
          localStorage.setItem("username", res.username);
          //return res.access_token;
          return res;
        }
        return null;
      }));
}

getRegisterAppToken() {
    const body = `grant_type=client_credentials`;
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/x-www-form-urlencoded')
    .append('Authorization', 'Basic ' + btoa(constants.TOKEN_REGISTER_APP_USERNAME + ':'+ constants.TOKEN_REGISTER_APP_PASSWORD));
   // console.log(headers);
    return this.httpClient.post(AuthenticationService.AUTH_TOKEN_URL, body, { headers:headers})
        .pipe(map((res: any) => {
          if (res.access_token) {
          //  console.log( res.access_token);
            return res.access_token;
          }
          return null;
        }));
}

validate2FAAnswers(answersModel: any) {

    return this.httpClient.post(AuthenticationService.VALIDATE_2FA_ANSWERS_URL, answersModel)
        .pipe(map((res: any) => {
            if (res) {
                return res;
            }
            return null;
        }));
}
//registration services
registerCaregiver(token: string, user: any) {

  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
 // console.log(AuthenticationService.USER_REGISTRATION_URL);
 // console.log('token  :' + token);
  return this.httpClient.post(AuthenticationService.USER_REGISTRATION_URL, user, { headers:headers })
      .pipe(map((res: any) => {
       // console.log("ser res "+res)
       this.userDetails = res;
              return res;
      }));
}
registerRecruiter(token: string, user: any) {

  const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append('Authorization', 'Bearer ' + token);
//  console.log(AuthenticationService.USER_REGISTRATION_URL);
//  console.log('token  :' + token);
  return this.httpClient.post(AuthenticationService.USER_REGISTRATION_URL, user, { headers:headers})
      .pipe(map((res: any) => {
        this.userDetails = res;
              return res;
      }));
}
validateOTPToken(Token: string, username:any){
const headers = new HttpHeaders()
.append('Content-Type', 'application/json')
.append('Authorization', 'Bearer ' + Token);
//console.log(AuthenticationService.USER_OTCVALIDATION_URL);
//console.log('token :'+ Token);
return this.httpClient.post(AuthenticationService.USER_OTCVALIDATION_URL, username, {headers:headers})
.pipe(map((result:any)=>{
  
    return result;
}))
}

checkLoginStatus():boolean {
  var loginCookie = localStorage.getItem('loginStatus');
  if(loginCookie == "1"){
    return true
  }
 return false;
}
caregiverCheck():boolean{
  if(localStorage.getItem('Authority')==='CARE_GIVER'){
    return true;
  }
  else if(localStorage.getItem('Authority')==='RECRUITER'){
    return false;
  }
  else if(localStorage.getItem('Authority')==='ADMIN'){
    return false;
  }
}
get isCaregiver(){
  return this.caregiverLogin.asObservable();
}
recruiterCheck():boolean{
  if(localStorage.getItem('Authority')==='RECRUITER'){
    return true;
  }
  else if(localStorage.getItem('Authority')==='CARE_GIVER'){
    return false;
  }
  else if(localStorage.getItem('Authority')==='ADMIN'){
    return false;
  }
}
get isRecruiter(){
  return this.recruiterLogin.asObservable()
}

adminCheck():boolean{
  if(localStorage.getItem('Authority')==='RECRUITER'){
    return false;
  }
  else if(localStorage.getItem('Authority')==='CARE_GIVER'){
    return false;
  }
  else if(localStorage.getItem('Authority')==='ADMIN'){
    return true;
  }
}
get isAdmin(){
  return this.adminLogin.asObservable()
}
get isLoggedIn()
{
localStorage.getItem('loginStatus');
 // console.log('loginstatus :' + this.loginStatus );
  return this.loginStatus.asObservable();
}
get currentUserName() {
  return this.UserName.asObservable();
}

}
