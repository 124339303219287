export const TOKEN_AUTH_USERNAME = 'trusted-app';
export const TOKEN_AUTH_PASSWORD = 'XY7kmzoNzl100';

export const TOKEN_REGISTER_APP_USERNAME = 'register-app';
export const TOKEN_REGISTER_APP_PASSWORD = 'XY7kmzoNzl100';

export const TOKEN_NAME = 'access_token';

export const TWO_FACTOR_AUTH_TOKEN = 'TWO-FACTOR-AUTH-TOKEN';
// export const GOOGLE_LOGIN_CLIENT_ID = '793763372527-auqnupeqg0ul92qcl0sl5v26t6lhr1ig.apps.googleusercontent.com';
export const GOOGLE_LOGIN_CLIENT_ID = '704831598573-nj7g09ek5cfmkna4juqummu329katfog.apps.googleusercontent.com';
export const FACEBOOK_LOGIN_CLIENT_ID = '305618189488659';
export const API_BASE_URL = '';
//export const API_BASE_URL = 'http://localhost:8080';


