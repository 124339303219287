import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,HttpRequest, HttpResponse} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "./shared-services/authentication.service";
import { UserService } from "./shared-services/userservice.service";
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';  // Import FingerprintJS

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private shownErrors = new Set<string>();  // To store unique error status and messages
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private userService : UserService
    ) {
    }
    private handleError(error:HttpErrorResponse) {
        //console.log("This is getting error:")
       // console.log(error);
        return throwError(error);
  }
  // Function to get the device_id (can be modified according to your needs)
  async getDeviceId(): Promise<string | null> {
    // Check if the device_id is stored in localStorage
    let deviceId = localStorage.getItem('device_id');
    
    if (!deviceId) {
      //If not available, generate it using FingerprintJS
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      deviceId = result.visitorId;
      localStorage.setItem('device_id', deviceId);  // Store for future use
    }
    
    return deviceId;
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable<HttpEvent<any>>(observer => {
      this.getDeviceId().then((deviceId) => {
        // Clone the request to add the device_id header
        const clonedRequest = req.clone({
          setHeaders: {
            'device-Id': deviceId ? deviceId : '',
          }
        });

        next.handle(clonedRequest).pipe(
          map((resp: any) => {
            if (resp instanceof HttpResponse) {
              return resp;
            }
          }),
          catchError((err) => {
            const errorKey = `${err.status}-${err.error?.error || err.error?.error_description || ''}`;

            // Check if the error is not already shown
            if (!this.shownErrors.has(errorKey)) {
              if (err.status === 412) {
                this.authenticationService.logout();
                this.userService.logout();
                this.authenticationService.loginStatus.next(false);
                this.authenticationService.caregiverLogin.next(false);
                this.authenticationService.recruiterLogin.next(false);
                this.router.navigateByUrl('/login');
                this.shownErrors.add(errorKey);
              } else if (err.status === 401 && err.error.error === "invalid_token") {
                alert('Session expired, please login again.');
                localStorage.removeItem('remember');
                localStorage.removeItem('Authority');
                localStorage.removeItem('jwt');
                localStorage.setItem('loginStatus', '0');
                this.router.navigateByUrl('');
                this.authenticationService.loginStatus.next(false);
                this.authenticationService.caregiverLogin.next(false);
                this.authenticationService.recruiterLogin.next(false);
                this.shownErrors.add(errorKey);
              } else if (err.status === 401 && localStorage.getItem('remember') === "Yes" && err.error.error_description.startsWith("Access token expired")) {
                alert('Session expired, please login again.');
                localStorage.removeItem('remember');
                localStorage.removeItem('jwt');
                localStorage.setItem('loginStatus', '0');
                this.router.navigateByUrl('/login');
                this.authenticationService.loginStatus.next(false);
                this.authenticationService.caregiverLogin.next(false);
                this.authenticationService.recruiterLogin.next(false);
                this.shownErrors.add(errorKey);
              } else if (err.status === 401 && err.error.error === "unauthorized") {
                localStorage.removeItem('remember');
                localStorage.removeItem('jwt');
                localStorage.setItem('loginStatus', '0');
                this.router.navigateByUrl('/login');
                this.authenticationService.loginStatus.next(false);
                this.authenticationService.caregiverLogin.next(false);
                this.authenticationService.recruiterLogin.next(false);
                this.shownErrors.add(errorKey);
              }

              // Set a timeout to remove the error from the Set after 5 seconds, allowing it to be shown again if it reoccurs.
              setTimeout(() => {
                this.shownErrors.delete(errorKey);
              }, 5000);
            }

            return throwError(err);
          })
        ).subscribe(
          event => observer.next(event),
          err => observer.error(err),
          () => observer.complete()
        );
      });
    });
  }
    // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   //req.headers.append("device-Id", "1234");
 
    //     return next.handle(req)
    //     .pipe(map(resp=>{
    //         if (resp instanceof HttpResponse) {
    //           //  console.log(resp);
    //           //  console.log(resp.body);
    //             return resp;
    //         }
    //     }),
    //     catchError(err=>  {
    //     //    console.log(err);
    //         if (err.status === 412 ) {
    //           //alert('Account should be validate, please validate your account by providing validation code');
    //             this.authenticationService.logout();
    //             this.userService.logout();
    //             this.authenticationService.loginStatus.next(false);
    //                this.authenticationService.caregiverLogin.next(false);
    //                this.authenticationService.recruiterLogin.next(false);
    //             this.router.navigateByUrl('/login');
    //             this.router.navigate(['/login'], { relativeTo:this.route});
    //       }
    //       else if(err.status === 401 && err.error.error == "invalid_token"){
    //         alert('session was expired, please login again to continue');
    //         localStorage.removeItem('remember');
    //           localStorage.removeItem('Authority');
    //         localStorage.removeItem('jwt');
    //         localStorage.setItem("loginStatus", '0');
    //         this.router.navigateByUrl('');
    //         this.router.navigate([''], { relativeTo:this.route});
    //         this.authenticationService.loginStatus.next(false);
    //                this.authenticationService.caregiverLogin.next(false);
    //                this.authenticationService.recruiterLogin.next(false);
    //       }
    //       else if(err.status === 401 && localStorage.getItem('remember') === "Yes" && err.error.error_description.startsWith("Access token expired") && (err.error != "unauthorized")){
    //           alert('session was expired, please login again to continue');
    //           localStorage.removeItem('remember');
    //         localStorage.removeItem('jwt');
    //         localStorage.setItem("loginStatus", '0');
    //         this.router.navigateByUrl('/login');
    //         this.router.navigate(['/login'], { relativeTo:this.route});
    //         this.authenticationService.loginStatus.next(false);
    //                this.authenticationService.caregiverLogin.next(false);
    //                this.authenticationService.recruiterLogin.next(false);
    //       }else if(err.status === 401 && localStorage.getItem('remember') === "Yes" && (err.error.error == "unauthorized")){
    //        // alert('Full authentication required, please login again to continue');
    //         localStorage.removeItem('remember');
    //       localStorage.removeItem('jwt');
    //       localStorage.setItem("loginStatus", '0');
    //       this.router.navigateByUrl('/login');
    //       this.router.navigate(['/login'], { relativeTo:this.route});
    //       this.authenticationService.loginStatus.next(false);
    //              this.authenticationService.caregiverLogin.next(false);
    //              this.authenticationService.recruiterLogin.next(false);
    //     }
    //       else if(err.status === 401 && (err.error.error == "unauthorized") && (localStorage.getItem('remember') === "No" ||  localStorage.getItem('remember') === null)){
    //         //alert('Full authentication required, please login again to continue');
    //         localStorage.removeItem('remember');
    //           localStorage.removeItem('Authority');
    //         localStorage.removeItem('jwt');
    //         localStorage.setItem("loginStatus", '0');
    //         this.router.navigateByUrl('');
    //         this.router.navigate([''], { relativeTo:this.route});
    //         this.authenticationService.loginStatus.next(false);
    //                this.authenticationService.caregiverLogin.next(false);
    //                this.authenticationService.recruiterLogin.next(false);
    //       }else if(err.status === 401 && (err.error.error != "unauthorized") && err.error.error_description.startsWith("Access token expired") && (localStorage.getItem('remember') === "No" ||  localStorage.getItem('remember') === null)){
    //         alert('session was expired, please login again to continue');
    //         localStorage.removeItem('remember');
    //           localStorage.removeItem('Authority');
    //         localStorage.removeItem('jwt');
    //         localStorage.setItem("loginStatus", '0');
    //         this.router.navigateByUrl('');
    //         this.router.navigate([''], { relativeTo:this.route});
    //         this.authenticationService.loginStatus.next(false);
    //                this.authenticationService.caregiverLogin.next(false);
    //                this.authenticationService.recruiterLogin.next(false);
    //       }
    //       return throwError(err);
    //     }
    //     )
    //     )
    // }
}
 