import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'appsecurity',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.css']
  })
export class SecurityComponent implements OnInit {
    
    constructor(){

    }
    ngOnInit() {
      document.body.style.backgroundImage = "none";
      window.scrollTo( 0, 0 );
      setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 500);
    document.getElementsByTagName('html')[0].scrollTop=0;
    }
}
