import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  imgUrl = `../../../assets/cms_illustration_1.png`;
  imgUrl2 = `../../../assets/cms_illustration_2.png`;
  loginStatus$: Observable<boolean>;

 // images = [62, 83, 466, 965, 982, 1043, 738].map((n) =>  `https://picsum.photos/id/${n}/900/500`);
  textsslide = [this.imgUrl,this.imgUrl2].map((n) =>  n);
  interval = 2000;
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
  constructor(
    private route:ActivatedRoute,
    private router:Router,
    private authenticationService:AuthenticationService
  ) { }

  ngOnInit(): void {
    setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 500);
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.loginStatus$ = this.authenticationService.isLoggedIn;
    this.loginStatus$.subscribe(data=>{
      console.log("data", data);
      if(data){
        document.getElementById('homeScreen').style.width = '95%';
        document.getElementById('homeScreen').style.marginLeft = 'auto';
       }
       else {
        document.getElementById('homeScreen').style.width = '100%';
       }
    })
  }
  caregiver(){
    this.router.navigateByUrl('./caregiver');
    this.router.navigate(['./caregiver'], { relativeTo:this.route})
  }
  recruiter(){
    this.router.navigateByUrl('./recruiter');
    this.router.navigate(['./recruiter'], { relativeTo:this.route})
  }
}


