
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<!------ Include the above in your HEAD tag ---------->
<div class="login" style="background-color:#2777BA;height: 669px;">
<!--<div class="wrapper fadeInDown">-->
  <div class="wrapper">
    <div style=" background: #ffd0d0;
    padding: 10px;
    border-left: 5px #d80404 solid;
    font-size: 15px;" *ngIf="credErr">
      <p><b>Error: </b>{{error}}</p>
      <p *ngIf="isNoOfTries"> <b>{{noOftries}}</b> attempts remaining.</p>
      <p *ngIf="isNoOfTries"><b>Warning: </b>After <b>3 consecutive</b> unsuccesssful login attempts, your account will be locked</p>
      </div>
  <div id="formContent">
    <!-- Login Form -->
    <div *ngIf="!isforgotPassword  && !isResetPassword && loginFormDisplay">
      <!--<div class="fadeIn first loginHeading">-->
        <div class="first loginHeading">
        <span>LOGIN</span>
       </div>
      
      <!--<div class="socialSection">
        <span class="socialSection">Login With</span>
      <a href="javascript:void(0)"><i class="fa fa-facebook-official socialIcons" (click)="signInWithFB()"></i></a>
      <a href="javascript:void(0)"><i class="fa fa-google socialIcons" (click)="signInWithGoogle()"></i></a>
      <a href="javascript:void(0)"><i class="fa fa-linkedin-square socialIcons" style="font-size:30px;color: #0077b5"></i></a>
      
     </div>-->
     <form [formGroup]="loginForm" (ngSubmit)="login(loginForm.value)" class="form">
       
     <div> <mat-form-field   appearance="outline"> 
        <mat-label style="font-size: 20px;">Email *</mat-label>
        <input matInput placeholder="Email Address" formControlName="username">
        <mat-error *ngIf="!loginForm.controls['username'].valid && loginForm.controls['username'].touched">
          {{ getErrorEmail() }}
        </mat-error>
      </mat-form-field>
    </div>
     <div> <mat-form-field  appearance="outline">
        <mat-label style="font-size: 20px;"> Password *</mat-label>
        <input matInput placeholder="Password"  [type]="hide ? 'text' : 'password'" formControlName="password" >
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility ' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="!loginForm.controls['password'].valid && loginForm.controls['password'].touched">
          {{ getErrorPassword() }}
        </mat-error>
      </mat-form-field>

    </div>
      <div class="rememberMe"> 
        <input type="checkbox"  (change)="updateRemember($event)"><label style="color: white;">Remember Me</label>
         <a class="forgotPasswordLink" (click)="forgotPassword()">
          Forgot Password?</a>
         
         </div>
         
         <p class="signuplink">
          <a [routerLink]="'/registration'" class="forgotPasswordLink">Register</a></p>
      <div class="form-element">
        <button mat-button *ngIf="!isloading" type="submit" class="button" [disabled]="!loginForm.valid">LOGIN</button>
        <button *ngIf="isloading" mat-button class="spinner button"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
      </div>
    </form>    
    </div>
    <!--  OTC form for validate if user would not activated at registration -->
    <div *ngIf="validateDeviceOtc && !isResetPassword && !isforgotPassword && !loginFormDisplay && !forgotPasswordSuccess">
      <div class="fadeIn first loginHeading">
        <span>Validate Your Account</span>
       </div>
       <br/>
      <div class="container-fluid" style="font-family: 'Century Gothic' !important;">
        <form [formGroup]="OTCValidateForm" (ngSubmit)="otcValidate(OTCValidateForm.value)" novalidate>
      
          <p style="color: white;" *ngIf="emailNotification && !mobileNotification">Verification code has been sent to <a style="color:#ffffff;"> {{ ' ' + activationEMail}}</a>.
             Please check your email and activate your account by validating the verification code.</p>
             <p style="color: white;" *ngIf="mobileNotification && !emailNotification">Verification code has been sent to you via text message
              .Please check your text message and activate your account by validating the verification code.</p>
              <p style="color: white;" *ngIf="mobileNotification && emailNotification">Verification code has been sent to <a style="color:#ffffff;"> {{ ' ' + activationEMail}}</a> and via text message
                .Please check your email/text message and activate your account by validating the verification code.</p>
          <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
            <mat-label style="font-size: 17px;"> Enter Verification code * </mat-label>
            <input matInput placeholder="Enter 4-digit verification code" formControlName="otc"   autocomplete="off">
          <mat-error *ngIf="!OTCValidateForm.controls['otc'].valid && OTCValidateForm.controls['otc'].touched">
            {{ getErrorOtcValidate() }}
          </mat-error>
        </mat-form-field>
        <div style="text-align: center;">
        <button *ngIf="!isOtcLoading" mat-button type="submit" class="validbutton" [disabled]="!OTCValidateForm.valid">Validate Account</button>
        <button *ngIf="isOtcLoading" mat-button class="validbutton spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
        </div>
        <p style="color: white;">​If you do not see an email after 5 minutes, please  <a class="resendButton" (click)="resendValidateOtc()" >click here</a></p>

    </form>
    </div>  

    </div>
     <!-- Forgot Password Form -->
<div *ngIf="isforgotPassword || !loginFormDisplay && !isResetPassword && !forgotPasswordSuccess && !validateDeviceOtc">
  <div class="fadeIn first loginHeading">
    <span>Forgot Password</span>
   </div>
   <br/>
   
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPassword(forgotPasswordForm.value)" novalidate>
    <div>
     
        <div> 
          <mat-form-field   appearance="outline"> 
            <mat-label style="font-size: 17px;">Email *</mat-label>
            <input matInput placeholder="Enter your email" formControlName="username"   autocomplete="off">
           <mat-error *ngIf="!forgotPasswordForm.controls['username'].valid && forgotPasswordForm.controls['username'].touched">
            {{  getErrorEmailorphone() }}
            </mat-error>
          </mat-form-field>
      </div>
    </div>
    <div style="text-align: center;">
      <button style="margin-right:10px" type="button" class="button" mat-button (click)="back()">
        Go Back</button>
    <button *ngIf="!isForgotLoading" mat-button type="submit" class="button" [disabled]="!forgotPasswordForm.valid">Submit</button>
    <button *ngIf="isForgotLoading" mat-button class="button"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
    </div>
    <!--<button style="display: flex;align-items: center;" class="btn btn-danger" (click)="back()">
      Go Back</button>-->
     </form>
</div>
 <!-- OTC Form] -->
    <div *ngIf="forgotPasswordSuccess && !isResetPassword && !isforgotPassword">
    <div class="fadeIn first loginHeading">
      <span>Reset your Password</span>
     </div>
     <br/>
    <div class="container-fluid" style="font-family: 'Century Gothic' !important;">
      <form [formGroup]="OTCForm" (ngSubmit)="resetPassword(OTCForm.value)" novalidate>
        <p style="color: white;" *ngIf="emailNotification && mobileNotification">A verification code has been sent to your email and phone number.Please enter the code below to verify your account</p>
        <p style="color: white;" *ngIf="emailNotification && !mobileNotification">A verification code has been sent to your email. Please enter the code below to verify your account</p>
        <p style="color: white;" *ngIf="mobileNotification && !emailNotification">A verification code has been sent to your phone number. Please enter the code below to verify your account</p>
        <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
          <mat-label style="font-size: 17px;"> Enter Verification code *</mat-label>
          <input matInput placeholder="Enter 4-digit verification code" formControlName="otc"   autocomplete="off">
          <mat-error *ngIf="!OTCForm.controls['otc'].valid && OTCForm.controls['otc'].touched">
            {{ getErrorOtc() }}
          </mat-error>
      </mat-form-field>
      <div style="text-align: center;">
        <button style="margin-right:10px;width:30%;" type="button" class="button" mat-button (click)="back()">
          Back to login</button>
      <button *ngIf="!isOtcLoading" mat-button type="submit" class="validbutton" [disabled]="!OTCForm.valid">Verify Account</button>
      <button *ngIf="isOtcLoading" mat-button class="validbutton spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
      </div>
      <p style="color: white;"> ​If you do not see an email after 5 minutes, please <a class="resendButton" (click)="resend()" >click here</a></p>

  </form>
  </div>  
    </div>
 <!-- Reset Password Form -->
    <div  *ngIf="isResetPassword || !loginFormDisplay && !isforgotPassword && !forgotPasswordSuccess && !validateDeviceOtc">
      <div class="fadeIn first loginHeading">
        <span>Reset Password</span>
      </div>
      <br/>
      
      <form [formGroup]="resetForm" (ngSubmit)="onReset()" novalidate>
        <div id="passwordFormat">
        <div> <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
          <mat-label> Enter Password *</mat-label>
          <input matInput placeholder="Password" [type]="hide ? 'text' : 'password'" formControlName="password"   autocomplete="off">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility ' : 'visibility_off'}}</mat-icon>
          <mat-error *ngIf="resetForm.controls['password'].dirty && !resetForm.controls['password'].valid" >
            {{errors.PasswordFormat}}
         </mat-error>
         <mat-error *ngIf="!resetForm.controls['password'].dirty && resetForm.controls['password'].touched && !resetForm.controls['password'].valid" >
          {{errors.Password}}
        </mat-error>
        </mat-form-field>
      </div>
      <div> <mat-form-field   appearance="outline" class="mat-form-field-outline"> 
        <mat-label> Confirm Password *</mat-label>
        <input matInput placeholder=" Confirm Password" [type]="hide1 ? 'text' : 'password'" formControlName="confirmPassword"   autocomplete="off" [errorStateMatcher]="matcher">
        <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility ' : 'visibility_off'}}</mat-icon>
        <mat-error *ngIf="resetForm.controls['confirmPassword'].dirty" >
          {{errors.ConfirmPassword}}
       </mat-error>
       <mat-error *ngIf="!resetForm.controls['confirmPassword'].dirty && resetForm.controls['confirmPassword'].touched && !resetForm.controls['confirmPassword'].valid" >
        {{errors.cnfmPassword}}
      </mat-error>
      </mat-form-field>
    </div>
    </div>
    <div style="text-align: center;">
      <button style="margin-right:10px;width:30%;" type="button" class="button" mat-button (click)="back()">
        Back to login</button>
        <button *ngIf="!isResetLoading" type="submit" mat-button class="button" [disabled]="!resetForm.valid">Reset</button>
        <button *ngIf="isResetLoading" mat-button class="button spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
</div>
      </form>
    </div>
</div>
</div>
</div>
<ng-template #sentOtc>
<div>
  {{text}}
  </div>
  <div style="text-align:center">
    <button style="outline:none;color:white;width:auto;padding: 5px 16px;" type="button" class="button" mat-dialog-close>OK</button>
    </div>
</ng-template>