import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component'
import { RegistrationComponent } from './authentication/registration/registration.component';
import { HomeComponent } from "./layout/home/home.component";
import {UploadDocumentsComponent } from "./documents/upload-documents/upload-documents.component";
import {  SenddocumentComponent } from "./documents/senddocument/senddocument.component";

import {AuthGuard } from "./authguard.guard";
import {LayoutRoutingModule } from './layout/layout-routing.module';
import {AuthRoutingModule} from './authentication/authentication-routing.module';
import{DocumentsRoutingModule} from './documents/documents-routing.module';
import { MainComponent } from './layout/main/main.component';
import{ LayoutModule } from './layout/layout.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { AdminPageRoutingModule } from './adminPage/adminPage-routing.module';

const routes: Routes = [
  { path: 'login', loadChildren:'./authentication/authentication.module#AuthenticationModule'},
  {path:'registration', loadChildren:'./authentication/authentication.module#AuthenticationModule'},
  {path:'profile',loadChildren:'./documents/documents.module#DocumentsModule'},
  {path:'upload-document',loadChildren:'./documents/documents.module#DocumentsModule'},
  {path:'send-document', loadChildren:'./documents/documents.module#DocumentsModule'},
  {path:'recruiteraccess-documents', loadChildren:'./documents/documents.module#DocumentsModule'},
  {path:'admin-main-apge', loadChildren:'./adminPage/adminPage.module#AdminPageModule'}
];

@NgModule({
  imports: [LayoutRoutingModule, AuthRoutingModule, DocumentsRoutingModule, AdminPageRoutingModule, RouterModule.forRoot(routes)],
  exports: [RouterModule, LayoutRoutingModule,AuthRoutingModule,DocumentsRoutingModule, AdminPageRoutingModule]
  // imports: [LayoutRoutingModule, AuthRoutingModule, DocumentsRoutingModule, RouterModule.forRoot(routes)],
  // exports: [RouterModule, LayoutRoutingModule,AuthRoutingModule,DocumentsRoutingModule]

})
export class AppRoutingModule { }
