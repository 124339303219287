import {ErrorStateMatcher } from "@angular/material/esm2015";
import {FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn} from '@angular/forms';
export class CustomValidators{
static checkDates: ValidatorFn  = (formGroup: FormGroup) => {
 const [ expiryDate, uploadDate] =  Object.keys(formGroup.controls || {});
 var endDate = formGroup.get(expiryDate).value;
 var startDate = formGroup.get(uploadDate).value;
 var today = new Date();
 if((endDate !== null) && startDate !== null && startDate !== "" && endDate !== ""){
 const isValid =(new Date(endDate) > new Date(startDate) );
 return isValid ? null : { notValid: true };
 }
 
}
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
      const invalidCtrl = !!(control && control.invalid && control.touched );
      const invalidParent = !!(control && control.parent.touched && control.parent.invalid && control.parent.hasError('notValid'));
      return (invalidCtrl || invalidParent );
    }
  }
  export const errorMessages: { [key: string]: string } ={
  expirydate:'Expiration-Date is required.',
  expirydateValid:'Please enter a valid expiration date.',
   uploaddate:'Start-Date/Issue-Date is required.',
   uploaddateValid:'Start-Date/Issue-Date should be valid.',
  }