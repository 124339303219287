<div class="container-fluid">
  <h1 style="color: white;font-family: 'Acumin Variable Concept';">Documents For Review</h1>
<div class="sub-container" *ngIf="!text" style="border-radius: 5px;height:630px">
  <div *ngIf="loading" class="wrapper">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <div class="shadow"></div>
    <span>Loading</span>
</div>
    <p *ngIf="!loading">
      <ag-grid-angular 
      #agGrid1
      style="width:inherit" 
      id="myGrid"
      class="ag-theme-alpine approveOrReject"
      [rowData]="rowData1" 
      [defaultColDef] ="defaultColDef"
      (gridReady)="onGridReady($event)"
      [domLayout]="domLayout"
      [frameworkComponents]="frameworkComponents"
      [suppressDragLeaveHidesColumns] = true
      [pagination] ="true"
      [paginationPageSize]= 10
      [cacheBlockSize]=  10
      (rowSelected)="onRowSelected($event)"
      rowSelection="multiple"
      [columnDefs]="columnDefs1"
      >
    </ag-grid-angular>
      </p>
      <div *ngIf="!loading" style="text-align: center;
      margin-top: 40px;">
      <button *ngIf="!isApproveLoading" mat-button class="approveBtn" (click)="approveBtn()" type="submit" [disabled]="!checkboxCheck">APPROVE</button>
      <button *ngIf="isApproveLoading" mat-button class="approveBtn"><i  class="fa fa-spinner" aria-hidden="true"></i></button>
  
      <button *ngIf="!isRejectLoading" mat-button class="rejectBtn" (click)="rejectBtn()" type="submit" [disabled]="!checkboxCheck">REJECT</button>
      <button *ngIf="isRejectLoading" mat-button class="rejectBtn"><i  class="fa fa-spinner" aria-hidden="true"></i></button>
  </div>
  
    </div>
    <div *ngIf="text" style="padding:10px;text-align: center;">
      <div *ngIf="loading" class="wrapper">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Loading</span>
    </div>
      <div style="text-align: left;background-color: #ffffff;padding:10px;border-radius: 5px;">
        <p style="color:#2777BA;font-size:20px;font-family: 'Acumin Variable Concept';"><i style="margin-right:10px" class="fa fa-info-circle"></i>No Documents Found</p>
        <p style="font-size:18px;font-family: 'Century Gothic'">Sorry, there are no requested documents to approve/reject.</p>
        </div>
        </div> 
  </div>
  
    