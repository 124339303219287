import { Component, ElementRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import * as fileSaver from 'file-saver';
import * as fileViewer from '@taldor-ltd/angular-file-viewer';
import { AgGridAngular, ICellRendererAngularComp } from "ag-grid-angular";
import { ShareDocumentsService } from "src/app/shared-services/share-documents.service";
import { map, mergeMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ArrayType } from "@angular/compiler";
import { FileMimeType, FileViewerComponent } from "@taldor-ltd/angular-file-viewer";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AddDocumentsComponent } from "../add-documents/add-documents.component";
@Component({
    selector: 'action-component',
    template:`<a><i (click)="viewbtn()" class="fa fa-eye" style="color:#2777BA;font-size:18px" title="View" ></i></a>
     <a><i *ngIf="!isDownload && !isDownloaded" (click)="downloadDocs()" class="fa fa-download" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Download"></i></a>
     <a><i *ngIf="isDownload && !isDownloaded" class="fa fa-spinner" style="color:#2777BA;font-size:18px;margin-left:20px;"></i></a>
     <a><i class="fa fa-edit" (click)="editbtn()" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Edit"></i></a>
     <a><i (click)="confirmDelete()" *ngIf="!isDelete" class="fa fa-trash" title="Delete" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Delete"></i>
     <a><i *ngIf="isDelete" class="fa fa-spinner" style="color:#2777BA;font-size:18px;margin-left:20px;"></i></a>
     <a><i (click)="shareDoc()" class="fa fa-share" style="color:#2777BA;font-size:18px;margin-left:20px;" title="Share"></i></a>
     <!-- confirmation for delete-->
<ng-template #ConfirmDelete>

   <div>
     <h4 class="modal-title w-100">Are you sure you want to delete this file?</h4>	
   </div>
   <div style="text-align:center">
   <button style="outline:none;" type="button" class="btn btn-danger" (click)="deletDocs()" mat-button mat-dialog-close>Delete</button>
   <button style="margin-left:5px;outline:none;" type="button" class="btn btn-secondary" mat-button mat-dialog-close>Cancel</button>
   </div>

</ng-template> 
   `,
   styles:[`
   .fa:hover{
     cursor:pointer;
  }
  .fa-trash, fa-arrow-circle-o-down, .fa-eye{
    transition:All .2s ;
  }
  i.fa-trash:hover {
    color:red !important;
    font-size:20px !important;
  }
  .fa-arrow-circle-o-down:hover, .fa-eye:hover {
    font-size:20px !important;
  }
  ::ng-deep .cdk-overlay-backdrop {
   /* position: static !important;
    top: auto !important;
    bottom: auto !important;
    left: auto !important;
    right: auto !important;
    z-index: auto !important;
    pointer-events: none !important;*/
    -webkit-tap-highlight-color: unset !important;
    transition: unset !important;
    opacity: unset !important;
  }
  
 `]
  
})
export class ActionComponent implements OnInit {
    public params: any;
    public gridApi;
    public gridColumnApi;
    public isDelete:boolean;
    public isDownload:boolean;
    public isDownloaded:boolean;
   // public showFile:boolean;
    @ViewChild("ConfirmDelete") ConfirmDelete:TemplateRef<any>; /** confirmation pop-up for delete doc */
    @Output('showFile') showFile:boolean;
    agInit(params: any): void {
        this.params = params;
     
      }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
   
  }
  ngOnInit(){
let canvas = document.getElementById("myCanvas");

  }
  constructor(
    public dialog: MatDialog,
    public sharedocumentsService:ShareDocumentsService,
    public sanitizer:DomSanitizer
  ){
  }
  confirmDelete(){ /** trigger when click on delete icon */
    this.dialog.open(this.ConfirmDelete);
  }
  editbtn(){ /** trigger when click on edit icon */
  const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    
    dialogConfig.data =  this.params.data ;
    this.dialog.open(AddDocumentsComponent, dialogConfig);
    this.sharedocumentsService.raiseEditEvent(this.params.data);
  }
viewbtn(){ /** trigger when click on eye icon */
  this.showFile = true;
  this.sharedocumentsService.downloadDocument(this.params.data.caregiverDocId, "view").
  subscribe(response => {
    let resp: any = response;
    if (!resp.body || resp.body == null || resp.body == 'null') {
      alert('Permission got expired or no file available!');
      return;
    }
    let filename = '';
    let contentType = resp.headers.get('content-type');
    var disposition = resp.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1])
        filename = matches[1].replace(/['"]/g, '');
    }
   
    let blob: any = new Blob([resp.body], { type: contentType });
   
    const url = window.URL.createObjectURL(blob);
    let data:any={};
    if(filename.indexOf('.pdf') !== -1){
      data.type = FileMimeType.PDF;
      data.src = url;
    }
    else if(filename.indexOf('.jpg') !== -1 || filename.indexOf('.jpeg') !== -1){
      data.type = FileMimeType.JPEG;
      data.src =  this.sanitizer.bypassSecurityTrustUrl(url);
    }
    else{
      data.type = FileMimeType.PNG;
      data.src = this.sanitizer.bypassSecurityTrustUrl(url);
    }
    
  
//this.sharedocumentsService.type= FileMimeType.PDF;
   this.sharedocumentsService.fileEvent(data);
   console.log(url);
   //console.log(document.getElementsByTagName('tld-file-viewer').item(0).innerHTML);
  }),
  error => console.log('Error downloading the file'),
             () => console.info('File downloaded successfully');
}
deletDocs(){ /** trigger when click on yes button delete confirmation pop-up */
  this.isDelete = true;
  this.sharedocumentsService.deletDocuments(this.params.data.caregiverDocId)
  .subscribe((res)=>{
    console.log(res);
    if(res){  
this.sharedocumentsService.raiseEvent();
    }
    return res;
  })
}
shareDoc(){ /** trigger when click on share icon */

    this.sharedocumentsService.shareDocEvent(this.params.data);
  
}
  downloadDocs() {
    this.isDownload = true;
    this.sharedocumentsService.downloadDocument(this.params.data.caregiverDocId, "download").
      subscribe(response => {
        this.isDownload = false;
       this.isDownloaded =false;
        let resp: any = response;
        if (!resp.body || resp.body == null || resp.body == 'null') {
          alert('Permission got expired or no file available!');
          return;
        }
        let filename = '';
        let contentType = resp.headers.get('content-type');
        var disposition = resp.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1])
            filename = matches[1].replace(/['"]/g, '');
        }
       
        let blob: any = new Blob([resp.body], { type: contentType });
       
        const url = window.URL.createObjectURL(blob);
        //window.open(url);
        //window.location.href = response.url;
        fileSaver.saveAs(blob, filename.slice(11));
      /* if(this.isDownloaded){
        setTimeout(()=>{
          this.isDownloaded = false;
         },3000)    
       }*/
      }),
     
      error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
                
  }

}