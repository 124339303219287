import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import * as moment from 'moment'; 
import { DownloadComponent } from './download.component';
import { UserService } from 'src/app/shared-services/userservice.service';
import * as fileSaver from 'file-saver';
import { ThrowStmt } from '@angular/compiler';
@Component({
selector:'app-recruiteraccess-documents',
templateUrl: "./recruiteraccess-documents.component.html",
  styleUrls: ["./recruiteraccess-documents.component.scss"]

})

export class RecruiteraccessDocuments implements OnInit {
    public frameworkComponents; /** to render download Component */
    public text:boolean;        /** display text if there is no access documents */
    public loading:boolean;     /**display spinner if value true */
    public domLayout;
    public pagination:boolean;
    public paginationPageSize;
    public alert;
    public gridApi;
  public isAlert;
 public src ;
 public type ;
 public showFile:boolean;
 public checkboxCheck;
   public selectedId = [];
   public params;
   public isDownloadLoading:boolean;
 public isSelected:boolean;;
 public sourcePath;
   private rowSelection;               /** row selections in tables */
 public fileName;
    @ViewChild("agGrid", {static: false}) agGrid:AgGridAngular;
    constructor(
      private shareDocumentsService:ShareDocumentsService,
      private userService:UserService
  ){
    this.domLayout = 'autoHeight';
    this.paginationPageSize = 10;
    this.rowSelection = 'multiple';

      this.frameworkComponents = {
        cellRenderer : DownloadComponent
      }
  }
ngOnInit(){
  this.shareDocumentsService.paginationChange.subscribe((res) =>{
    this.gridApi.api.paginationGoToPage(0);
  })
  window.scrollTo( 0, 0 );
  document.getElementsByTagName('html')[0].scrollTop=0;
  sessionStorage.setItem('isNextTab', 'yes');
  document.body.style.backgroundImage = "none";
  this.shareDocumentsService.badgeCountEvent();
  this.loading = true;
 // this.badgeCount();
  this.shareDocumentsService.recruiterAccessibleDocs() /** to get recruiter accessible docs */
  .subscribe((res)=>{
 // console.log('response', res);
  this.rowData = res.body.accessibleDocs;
  this.rowData.sort(function(a, b) {
  var nameA = a.docApprovedDate;
  var nameB = b.docApprovedDate; 
  if (nameA < nameB) {
  return 1;
  }
        if (nameA > nameB) {
          return -1;
        }
      
        // names must be equal
        return nameB - nameA;
      });
      this.loading = false;
      if(res.body.accessibleDocs.length == 0){
        this.text = true;
      }
      else{
        this.text =false;
        if(res.body.accessibleDocs.length > 10){
          this.pagination = true;
        }
      }
      return res;
    },
    (err)=>{
    //  console.log('err', err);
    }
    )
    this.shareDocumentsService.viewFile.subscribe((res)=>{   /** raise event when click on eye icon for view file*/
      this.src = res.src;
      this.type = res.type;
      this.showFile = true;
     // this.dialog.open(this.fileViewer);
    })
}
/*badgeCount(){
  this.shareDocumentsService.badgeCounts()
  .subscribe((res)=>{
   // console.log(res);
    
    this.alert = res.body.recruiterDocPermissionCount;
    this.shareDocumentsService.alert = res.body.recruiterDocPermissionCount;
    //this.shareDocumentsService.alert.next(this.alert);
    if(this.alert==0){
      this.shareDocumentsService.isAlert = false;
    }else{
      this.shareDocumentsService.isAlert= true;
    }
   // console.log(this.alert);
      return res;
  },
  (err)=>{
   // console.log('err', err);
  }
  )

}*/
closeFile(){
  this.showFile = false;
      }
columnDefs = [
{field:"documentName",
headerCheckboxSelection: params => {
  const checkBox = document.createElement('input');
  checkBox.setAttribute('type', 'checkbox');
  checkBox.checked = false;
 //console.log('isBooked', params)
  const label = document.createElement('label');
  const title = document.createTextNode(params.colDef.headerName);
  label.appendChild(checkBox);
  label.appendChild(title);
  return label;
} , 
  checkboxSelection: true,
  cellRenderer: (params) => {
    this.params = params;
   // console.log('isBooked', params);
    return params.data.documentName;
  },
 width:270, filter:true, resizable: true},
{field:"documentType", width:250, filter:true, resizable: true},
{headerName:'Caregiver FirstName', filter:true, field:'nurseFirstName', width:250, resizable: true},
{headerName:'Caregiver LastName', filter:true, field:'nurseLastName', width:250, resizable: true}
, {field:"expirationDate", width:250, valueFormatter:this.dateformatter,
filter: 'agDateColumnFilter',
  filterParams:{
    comparator: (filterLocalDateAtMidnight, cellValue)=> {
      var dateAsString = this.dateformatter(cellValue);
      if (dateAsString == null) return 0;
      var dateParts = dateAsString.split('/');
      const day = Number(dateParts[1]);
      const month = Number(dateParts[0]) - 1;
      const year = Number(dateParts[2]);
      var cellDate = new Date(year, month, day);
  
     if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }},
 resizable: true},
{ field: "Actions", cellRenderer: "cellRenderer", resizable: true, width:200 }
];
rowData = [];
defaultColDef= [{
  enableRowGroup: true,
  colResizeDefault:'shift',
  sortable: true,
  filter: true,
  resizable: true,
}]
dateformatter(params){ /** for date format */
if(params.value !=null){
  var dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[0].split("-");
  var date = dateSplit1[2];
  var month = dateSplit1[1];
  var year = dateSplit1[0];
  var fullDate = month.concat("/", date, "/", year);
  return fullDate;
}
  
 // return moment(params.value).format('DD/MM/YYYY'); 
}
onGridReady(params){
  this.gridApi = params;
   // params.api.sizeColumnsToFit();
   if(window.matchMedia("(min-width: 1260px)").matches){
    params.api.sizeColumnsToFit();
  }
    window.addEventListener("resize", function () {
      setTimeout(function () {
        if(window.matchMedia("(min-width: 1260px)").matches){
        params.api.sizeColumnsToFit();
      }
      });
    });
  
}

onRowSelected(event) {
  // console.log(event);
  
   let i = event.rowIndex;
   console.log(document.getElementById('download'));
 
   this.checkboxCheck = event.node.selected;
   
   console.log('isBooked', this.params);
   if(this.checkboxCheck) {
     this.isSelected = true;
     this.selectedId.push(this.rowData[i].caregiverDocId);
     //this.shareDocumentsService.aclicked(this.selectedId);
   //  console.log(this.selectedId);
   }
   else{
   //  this.shareDocumentsService.checked = false;
   var removeIndex1 = this.selectedId.map(function(item){return item;}).indexOf(event.data.caregiverDocId);
   if(removeIndex1 != -1){
     this.selectedId.splice(removeIndex1, 1);
     if(this.selectedId.length == 0){
       this.isSelected = false;
     }
     else{
       this.isSelected = true;
     }
   //  console.log(this.selectedId);
   
   }
 }
 
 }
 btnClickedHandler() {

   for(let i=0;i<this.selectedId.length;i++){
    this.isDownloadLoading = true;
    var sendData:any =[];
    sendData.selectedId=this.selectedId[i];
    sendData.isDone = false;
    sendData.selectedIds = this.selectedId;
    this.userService.downloadDocEvent(sendData);
    this.downloadDocs(this.selectedId[i]);
   }
 }
 downloadDocs(selectedId) {
  this.shareDocumentsService.downloadDocument(selectedId, "download").
    subscribe(response => {
      var sendData:any=[];
      sendData.selectedId=selectedId;
      sendData.isDone = true;
      sendData.selectedIds = this.selectedId;
      this.userService.downloadDocEvent(sendData);
      this.isDownloadLoading = false;
      this.isSelected = false;
      this.gridApi.api.setColumnDefs(this.columnDefs);
      this.gridApi.api.setRowData(this.rowData);
      this.onGridReady(this.gridApi)
      this.params.node.selectionController.selectedNodes =[];
      this.selectedId = [];
      let resp: any = response;
      if (!resp.body || resp.body == null || resp.body == 'null') {
        alert('Permission got expired or no file available!');
        return;
      }
      let filename = '';
      let contentType = resp.headers.get('content-type');
      var disposition = resp.headers.get('Content-Disposition');
      if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1])
          filename = matches[1].replace(/['"]/g, '');
          this.fileName = filename.slice(11);
      }
      
      let blob: any = new Blob([resp.body], { type: contentType });
      const url = window.URL.createObjectURL(blob);
      this.sourcePath = url;
      //window.open(url);
      //window.location.href = response.url;
      fileSaver.saveAs(blob, filename.slice(11));
    }),
    error => console.log('Error downloading the file'),
               () => console.info('File downloaded successfully');
}
}