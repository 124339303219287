import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../authguard.guard';
import { AdminMainPageComponent } from './adminMainPage/adminMainPage.component';
import { DocumentTrackingComponent } from './documentTracking/documentTracking.component';
import { UserActivityRepComponent } from './userActivityRep/userActivityRep.component';
import { UserSessionRepComponent } from './userSessionRep/userSessionRep.component';
import { AdminAuthGuard } from '../adminauth.guard';



const routes: Routes = [
    {
     path:'admin-main-page', component:AdminMainPageComponent, canActivate: [AdminAuthGuard]
    },
    {
        path:'user-session-rep', component:UserSessionRepComponent, canActivate: [AdminAuthGuard]
    },
    {
        path:'user-activity-rep', component:UserActivityRepComponent, canActivate: [AdminAuthGuard]
    },
    {
        path:'document-tracking', component:DocumentTrackingComponent, canActivate: [AdminAuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminPageRoutingModule {}