import { Component, OnInit } from '@angular/core';
@Component({
    selector: 'app-for_caregiver',
    templateUrl: './caregiver.component.html',
    styleUrls: ['./caregiver.component.css']
  })
export class CaregiverComponent implements OnInit {
    
    constructor(){

    }
    ngOnInit() {
      document.body.style.backgroundImage = "none";
      window.scrollTo( 0, 0 )
      setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 500);
    document.getElementsByTagName('html')[0].scrollTop=0;
    }
     signUpButton() {
     sessionStorage.setItem('isCaregiverOrRecruiter', 'caregiver');
    }
    
}
