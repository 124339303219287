<div class="container-fluid requestDoc" id="requestDocId">
  <h1 *ngIf="displayDocs || text" style="font-family: 'Acumin Variable Concept';"> Request Document Management</h1> 
  <div style="padding:10px;border-radius: 5px;height:630px" id="subRequestDocId">  
      <div class="wrapper fadeInDown" *ngIf="!displayDocs && !text">
        <div id="formContent">
    <div class="fadeIn first loginHeading">
        <span>Request Document</span>
       </div>
    <form [formGroup]="requestDocForm" (ngSubmit)="formSubmit()">
      <div style="text-align: center;margin-top:10px;margin-bottom: 10px;font-size:16px;color:rgb(255, 255, 255);border-radius:2px;font-family: 'Century Gothic';padding:3px">
        <span>Search for caregivers by Email or Phone Number</span>
      </div>
        <div>
        <mat-form-field   appearance="outline"> 
            <mat-label style="font-size: 17px;"> Email/Phone </mat-label>
            <input matInput placeholder="Enter email or phone" formControlName="details"   autocomplete="off">
           <mat-error *ngIf="!requestDocForm.controls['details'].valid && requestDocForm.controls['details'].touched">
            {{getErrorEmailorphone()}}
            </mat-error>
          </mat-form-field>
          </div>
          <div class="form-element" style="text-align: center;">
            <button mat-button  *ngIf="!isloading" type="submit" class="button" [disabled]="!requestDocForm.valid">Check</button>
            <button mat-button  *ngIf="isloading" class="button spinner"><i  class="fa fa-spinner" style="text-align: center;" aria-hidden="true"></i></button>
          </div>
        </form>
    </div>
    </div>
    <div>
    <div class="caregiverdetails" style="padding: 15px 10px 0 10px;" *ngIf="displayDocs || text">
      <h1 style="font-size:20px;font-family: 'Acumin Variable Concept';">Caregiver Details<i *ngIf="isExpand && !isCollapse" (click)="expand()" class="fa fa-caret-down caret"></i>
        <i (click)="collapse()" *ngIf="!isExpand && isCollapse" class="fa fa-caret-right caret"></i></h1>
      
    <p *ngIf="isExpand && !isCollapse">
      <ag-grid-angular 
       #agGrid
       style="width:inherit" 
       id="myGrid"
       class="ag-theme-alpine"
       [domLayout]="domLayout"
       [rowData]="rowData1" 
       (gridReady)="onGridReady1($event)"
   rowSelection="multiple"
   [columnDefs]="columnDefs1"
   [suppressDragLeaveHidesColumns] = true
   [defaultColDef]="defaultColDef"

   [rowClassRules]="rowClassRules">
     </ag-grid-angular>
       </p>
      </div>
    <div style="margin: 0 10px 10px 10px;padding-top:none"  *ngIf="displayDocs && !text">
      <h1 style="font-size:20px;font-family: 'Acumin Variable Concept';">Caregiver Documents</h1>
    <p>
       <ag-grid-angular 
        #agGrid
        style="width:inherit" 
        id="myGrid"
        class="ag-theme-alpine"
        [rowData]="rowData" 
        [domLayout]="domLayout"
        (gridReady)="onGridReady($event)"
        [frameworkComponents]="frameworkComponents"
    rowSelection="multiple"
    [columnDefs]="columnDefs"
    [suppressDragLeaveHidesColumns] = true
    [pagination] = pagination
    [paginationPageSize]="paginationPageSize"
    [defaultColDef]="defaultColDef"
    (rowSelected)="onRowSelected($event)"
    [rowClassRules]="rowClassRules">
      </ag-grid-angular>
        </p>
        <div style="display: flex;justify-content: space-between;">
        <button  class="btn btn-danger" type="button" (click)="backbtnHandler()">Go Back</button>
        <button *ngIf="!isRequestLoading" class="btn btn-primary" (click)="btnClickedHandler()" type="submit" [disabled]="!isSelected">Request Documents</button>
        <button *ngIf="isRequestLoading" class="btn btn-primary"><i class="fa fa-spinner"></i></button>
        </div>
     </div>
</div>
<div *ngIf="text" style="padding:10px;border-radius: 5px;">
  <div style="text-align: left;background-color: #ffffff;padding:10px;margin-bottom: 10px;border-radius: 5px;">
    <div>
    <p style="color:#2777BA;font-size:20px;font-family: 'Acumin Variable Concept'"><i style="margin-right:10px" class="fa fa-info-circle"></i>No Documents Found</p>
    <p style="font-size:18px;color: #000;font-family: 'Century Gothic'">Sorry, there are no documents for this caregiver.</p>
    </div>
    </div>
    <div>
      <button class="btn btn-danger" type="button" (click)="backbtnHandler()">Go Back</button>
      </div>
    </div> 

</div>
