import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { AuthenticationService } from 'src/app/shared-services/authentication.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  public isOpenUpload:boolean;
  public isOpenShare:boolean;
  public isOpenEdit:boolean;
  public isOpenApp_Rej:boolean;
  public isOpenProfile:boolean;
  public isOpenAcc:boolean;
  public isOpenReq:boolean;
  public present_text='';
  public isStep1:boolean;
  public isStep2:boolean;
  public isStep3:boolean;
  public isStep4:boolean;
  public isStep5:boolean;
  public isStep6:boolean;
  public isStep7:boolean;
  public isStep8:boolean;
  public loginData;
  public buttonText:string = "Expand All";
  public resetButtonText:string = "Expand All";
  public forgotButtonText:string = "Expand All";
  public isCollapse:boolean;
  public isResetCollapse:boolean;
  public isforgotCollapse:boolean;
  public username = localStorage.getItem('fullName');
  loginStatus$: Observable<boolean>;
  caregiverLogin$:Observable<boolean>;
  recruiterLogin$:Observable<boolean>;
  @ViewChild('img') img:TemplateRef<any>;
  constructor(
    private dialog:MatDialog,
    private authenticationService:AuthenticationService,
  ) { }

  ngOnInit(): void {
    setTimeout(() =>document.getElementsByTagName('html')[0].scrollTop=0, 500);
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.loginStatus$ = this.authenticationService.isLoggedIn;
   this.caregiverLogin$ = this.authenticationService.isCaregiver;
   this.recruiterLogin$ = this.authenticationService.isRecruiter;
   this.loginStatus$.subscribe(data=>{
    console.log("data", data);
    if(!data){
      document.getElementById('helpScreen').style.width = '100%';
      document.getElementById('helpScreen').style.height = 'auto';
     }
  })
  }
  checkLoginStatus(){
this.loginStatus$.subscribe(data=>{
      if(!data){
        document.getElementById('helpScreen').style.height = '690px';
       }
    })
  }
  expandOrCollapse(value, string) {
    console.log(string);
    if(value == true && string === 'notProfile'){
      this.buttonText = "Collapse All";
      this.isStep1 = true;
      this.isStep2 = true;
      this.isStep3 = true;
      this.isStep4 = true;
      document.getElementById('helpScreen').style.height = 'auto';
      let len = document.getElementsByClassName('steps').length
      for(let i=1;i<=len;i++){
        document.getElementById('Step'+i).classList.add('allSteps');
      }
    }
    else if(value == false && string === 'notProfile'){
      this.buttonText = "Expand All";
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = false;
      this.isStep4 = false;
      document.getElementById('helpScreen').style.height = '690px';
      let len = document.getElementsByClassName('steps').length
      for(let i=1;i<=len;i++){
        document.getElementById('Step'+i).classList.remove('allSteps');
      }
    }
    if(value == true && string === 'profileEdit'){
      this.buttonText = "Collapse All";
      this.isStep1 = true;
      this.isStep2 = true;
      document.getElementById('helpScreen').style.height = 'auto';
      let len = document.getElementsByClassName('steps').length
      for(let i=1;i<=2;i++){
        document.getElementById('Step'+i).classList.add('allSteps');
      }
    }else if(value == false && string === 'profileEdit'){
      this.buttonText = "Expand All";
      this.isStep1 = false;
      this.isStep2 = false;
      document.getElementById('helpScreen').style.height = 'auto';
      for(let i=1;i<=2;i++){
        document.getElementById('Step'+i).classList.remove('allSteps');
      }
    }
    if(value == true && string === 'pswReset'){
      this.resetButtonText = "Collapse All";
      this.isStep3 = true;
      this.isStep4 = true;
      document.getElementById('helpScreen').style.height = 'auto';
      for(let i=3;i<=4;i++){
        document.getElementById('Step'+i).classList.add('allSteps');
      }
    }else if(value == false && string === 'pswReset'){
      this.resetButtonText = "Expand All";
      this.isStep3 = false;
      this.isStep4 = false;
      document.getElementById('helpScreen').style.height = 'auto';
      for(let i=3;i<=4;i++){
        document.getElementById('Step'+i).classList.remove('allSteps');
      }
    }
    if(value == true && string === 'forgotPsw'){
      this.forgotButtonText = "Collapse All";
      this.isStep5 = true;
      this.isStep6 = true;
      this.isStep7 = true;
      document.getElementById('helpScreen').style.height = 'auto';
      for(let i=5;i<=7;i++){
        document.getElementById('Step'+i).classList.add('allSteps');
      }
    }else if(value == false && string === 'forgotPsw'){
      this.forgotButtonText = "Expand All";
      this.isStep5 = false;
      this.isStep6 = false;
      this.isStep7 = false;
      this.loginStatus$.subscribe(data=>{
        this.loginData = data;
      })
      if(this.loginData){
        document.getElementById('helpScreen').style.height = 'auto';
      }else {
        document.getElementById('helpScreen').style.height = '690px';
      }
      for(let i=5;i<=7;i++){
        document.getElementById('Step'+i).classList.remove('allSteps');
      }
    }
  }
  isOpenUpload_doc(){
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenUpload = true;
    this.isOpenShare = false;
    this.isOpenEdit = false;
    this.isOpenProfile = false;
    this.isOpenApp_Rej = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.present_text = 'UPLOAD DOCUMENTS'
    this.checkLoginStatus();
  }
  isOpenShare_doc(){
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenShare = true;
    this.isOpenUpload = false;
    this.isOpenApp_Rej = false;
    this.isOpenProfile = false;
    this.isOpenEdit = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.present_text = 'SHARE DOCUMENTS';
    this.checkLoginStatus()
  }
  isOpenEdit_doc() {
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenEdit = true;
    this.isOpenShare = false;
    this.isOpenUpload = false;
    this.isOpenApp_Rej = false;
    this.isOpenProfile = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.present_text = 'EDIT DOCUMENTS';
    this.checkLoginStatus();
  }
  isOpenAPP_REJ() {
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenApp_Rej = true;
    this.isOpenEdit = false;
    this.isOpenShare = false;
    this.isOpenUpload = false;
    this.isOpenProfile = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.present_text = 'APPROVE/REJECT DOCUMENTS';
    this.checkLoginStatus();
  }
  isOpenProfileSettings (){
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenProfile = true;
    this.isOpenApp_Rej = false;
    this.isOpenEdit = false;
    this.isOpenShare = false;
    this.isOpenUpload = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.loginStatus$.subscribe(data=>{
      if(!data){
        document.getElementById('helpScreen').style.height = '690px';
        this.present_text = ' FORGOT PASSWORD';

       }
       else {
        document.getElementById('helpScreen').style.height = 'auto';
        this.present_text = 'PROFILE SETTINGS';

       }
    })
  }
  isOpenReq_doc() {
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenReq = true;
    this.isOpenAcc = false;
    this.isOpenProfile = false;
    this.isOpenApp_Rej = false;
    this.isOpenEdit = false;
    this.isOpenShare = false;
    this.isOpenUpload = false;
    this.present_text = 'REQUEST DOCUMENTS';
    this.checkLoginStatus();
  }
  isOpenAccess_doc() {
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenAcc = true;
    this.isOpenReq = false;
    this.isOpenProfile = false;
    this.isOpenApp_Rej = false;
    this.isOpenEdit = false;
    this.isOpenShare = false;
    this.isOpenUpload = false;
    this.present_text = 'ACCESS DOCUMENTS';
    this.checkLoginStatus();
    this.isStep1 = true;
    setTimeout(() => {
      var helpStep1 = document.getElementById('Step1');
      helpStep1.style.backgroundColor = "#AA004D";
      helpStep1.style.color = "#ffffff";
    }
    , 5);
  }
  go_back() {
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.isOpenUpload =false;
    this.isOpenShare = false;
    this.isOpenApp_Rej = false;
    this.isOpenEdit = false;
    this.isOpenProfile = false;
    this.isOpenReq = false;
    this.isOpenAcc = false;
    this.isStep1=false;
    this.isStep2 = false;
    this.isStep3 = false;
    this.isStep4 = false;
    this.isStep5=false;
    this.isStep6 = false;
    this.isStep7 = false;
    this.isStep8 = false;
    this.loginStatus$.subscribe(data=>{
      if(!data){
        document.getElementById('helpScreen').style.height = 'auto';
       }
       else {
        document.getElementById('helpScreen').style.height = '690px';
       }
    })
  }
  openImage(value){
   // let
   this.dialog.open(this.img, {panelClass: 'my-custom-dialog-class'})
    console.log(value);
    var helpImage = document.getElementById("helpImage");
    helpImage.setAttribute('src', value);
    helpImage.style.width = '1000px';
  }
  step(value){
   var helpStep1 = document.getElementById('Step1');
   var helpStep2 = document.getElementById('Step2');
   var helpStep3 = document.getElementById('Step3');
   var helpStep4 = document.getElementById('Step4');
   var helpStep5 = document.getElementById('Step5');
   var helpStep6 = document.getElementById('Step6');
   var helpStep7 = document.getElementById('Step7');
    if(value === 'step1'){
      this.isStep1 = !this.isStep1;
      if(this.isStep1){
        helpStep1.classList.add('allSteps');
      }
      else{
        helpStep1.classList.remove('allSteps');
      }
    }
    else if(value === 'step2'){
      this.isStep2 = !this.isStep2;
      if(this.isStep2){
        helpStep2.classList.add('allSteps');
      }
      else{
        helpStep2.classList.remove('allSteps');
      }
    }
    else if(value === 'step3'){
      this.isStep3 = !this.isStep3;
      if(this.isStep3){
        helpStep3.classList.add('allSteps');
      }
      else{
        helpStep3.classList.remove('allSteps');
      }
    }
    else if(value === 'step4'){
      this.isStep4 = !this.isStep4;
      if(this.isStep4){
        helpStep4.classList.add('allSteps');
      }
      else{
        helpStep4.classList.remove('allSteps');
      }
    }
    else if(value === 'step5'){
      this.isStep5 = !this.isStep5;
      if(this.isStep5){
        helpStep5.classList.add('allSteps');
      }
      else{
        helpStep5.classList.remove('allSteps');
      }
    }
    else if(value === 'step6'){
      this.isStep6 = !this.isStep6;
      if(this.isStep6){
        helpStep6.classList.add('allSteps');
      }
      else{
        helpStep6.classList.remove('allSteps');
      }
    }
    else if(value === 'step7'){
      this.isStep7 = !this.isStep7;
      if(this.isStep7){
        helpStep7.classList.add('allSteps');
      }
      else{
        helpStep7.classList.remove('allSteps');
      }
    }
    this.loginStatus$.subscribe(data=>{
      this.loginData = data;
    })
    if(!this.isOpenProfile){
      if((this.isStep1 && this.isStep2) || (this.isStep1 && this.isStep3) || (this.isStep1 && this.isStep4) || (this.isStep2 && this.isStep3) || (this.isStep2 && this.isStep4) || (this.isStep3 && this.isStep4) || (this.isStep1 && this.isStep2 && this.isStep3) || (this.isStep1 && this.isStep2 && this.isStep4) || (this.isStep1 && this.isStep4 && this.isStep3) || (this.isStep4 && this.isStep2 && this.isStep3) || (this.isStep1 && this.isStep2 && this.isStep3 && this.isStep4)){
        document.getElementById('helpScreen').style.height = 'auto';
      }
      else {
        document.getElementById('helpScreen').style.height = '690px';
      }
      if(this.isStep1 && this.isStep2 && this.isStep3 && this.isStep4){
        this.isCollapse = true;
        this.buttonText = "Collapse All";
      }
      else {
        this.isCollapse = false;
        this.buttonText = "Expand All";
      }
    }
    else if(this.isOpenProfile && this.loginData){
      document.getElementById('helpScreen').style.height = 'auto';
      if(this.isStep1 && this.isStep2){
        this.isCollapse = true;
        this.buttonText = "Collapse All";
      }
      else {
        this.isCollapse = false;
        this.buttonText = "Expand All";
      }
      if(this.isStep3 && this.isStep4){
        this.isResetCollapse = true;
        this.resetButtonText = "Collapse All";
      }
      else {
        this.isResetCollapse = false;
        this.resetButtonText = "Expand All";
      }
      if(this.isStep5 && this.isStep6 && this.isStep7){
        this.isforgotCollapse = true;
        this.forgotButtonText = "Collapse All";
      }
      else {
        this.isforgotCollapse = false;
        this.forgotButtonText = "Expand All";
      }
    }
    else if(this.isOpenProfile && !this.loginData){
      if(this.isStep5 && this.isStep6 && this.isStep7){
        this.isforgotCollapse = true;
        this.forgotButtonText = "Collapse All";
      }
      else {
        this.isforgotCollapse = false;
        this.forgotButtonText = "Expand All";
      }
      if((this.isStep5 && this.isStep6) || (this.isStep5 && this.isStep7) || (this.isStep6 && this.isStep7) || (this.isStep5 && this.isStep6 && this.isStep7)){
        document.getElementById('helpScreen').style.height = 'auto';
      }
      else {
        document.getElementById('helpScreen').style.height = '690px';
      }
    }
  
  }

}
