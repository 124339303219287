import { Injectable, Inject } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { JwtHelperService } from './jwthelper.service';
import { JWT_OPTIONS } from './jwtoptions.token';
import { Observable, from, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { parse } from 'url';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    tokenGetter: () => string | null | Promise<string | null>;
    token2FAGetter: () => string | null | Promise<string | null>;
    headerName: string;
    headerName2FA: string;
    authScheme: string;
    whitelistedDomains: Array<string | RegExp>;
    blacklistedRoutes: Array<string | RegExp>;
    throwNoTokenError: boolean;
    skipWhenExpired: boolean;

    constructor(
        @Inject(JWT_OPTIONS) config: any,
        public jwtHelper: JwtHelperService
    ) {
        this.tokenGetter = config.tokenGetter;
        this.token2FAGetter = config.token2FAGetter;
        this.headerName = config.headerName || 'Authorization';
        this.headerName2FA = config.headerName2FA || 'TWO-FACTOR-AUTH-TOKEN';
        this.authScheme =
            config.authScheme || config.authScheme === ''
                ? config.authScheme
                : 'Bearer ';
        this.whitelistedDomains = config.whitelistedDomains || [];
        this.blacklistedRoutes = config.blacklistedRoutes || [];
        this.throwNoTokenError = config.throwNoTokenError || false;
        this.skipWhenExpired = config.skipWhenExpired;
    }

    isWhitelistedDomain(request: HttpRequest<any>): boolean {
        const requestUrl: any = parse(request.url, false, true);

        return (
            requestUrl.host === null ||
            this.whitelistedDomains.findIndex(
                domain =>
                    typeof domain === 'string'
                        ? domain === requestUrl.host
                        : domain instanceof RegExp
                            ? domain.test(requestUrl.host)
                            : false
            ) > -1
        );
    }

    isBlacklistedRoute(request: HttpRequest<any>): boolean {
        const url = request.url;

        return (
            this.blacklistedRoutes.findIndex(
                route =>
                    typeof route === 'string'
                        ? route === url
                        : route instanceof RegExp
                            ? route.test(url)
                            : false
            ) > -1
        );
    }

    handleInterception(
        token: string | null,
        token2FA: string | null,
        request: HttpRequest<any>,
        next: HttpHandler
    ) {
        let tokenIsExpired = false;

        if (!token && this.throwNoTokenError) {
            throw new Error('Could not get token from tokenGetter function.');
        }

        if (this.skipWhenExpired) {
            tokenIsExpired = token ? this.jwtHelper.isTokenExpired(token) : true;
        }

        if (token && tokenIsExpired && this.skipWhenExpired) {
            request = request.clone();
        } else if (
            token &&
            this.isWhitelistedDomain(request) &&
            !this.isBlacklistedRoute(request)
        ) {
            request = request.clone({
                setHeaders: {
                    [this.headerName]: `${this.authScheme}${token}`
                }
            });
        }

        if (token2FA && token && this.jwtHelper.decodeToken(token).client_id === 'trusted-app') {
            if (request.headers.has(this.headerName2FA)) {
                request = request.clone();
            } else {
                request = request.clone({
                    setHeaders: {
                        [this.headerName2FA]: `${token2FA}`
                    }
                });
            }
        }

        return next.handle(request);
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token: any = this.tokenGetter();
        const token2FA: any = this.token2FAGetter();
        if (token instanceof Promise && token2FA instanceof Promise) {
            return forkJoin(token, token2FA).pipe(mergeMap((asyncToken: string[] | null) => {
                return this.handleInterception(asyncToken[0], asyncToken[1], request, next);
            }));
        } else {
            return this.handleInterception(token, token2FA, request, next);
        }
    }
}
