<div class="container-fluid">
    <h1 style="font-family: 'Acumin Variable Concept' !important;font-size: 25px;margin:5px 10px;">Document Tracking</h1>
    <div class="sub-container">
        <div *ngIf="loading" class="wrapper">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
            <div class="shadow"></div>
            <span>Loading</span>
        </div>
        <div *ngIf="!loading" style=" display: flex;
        justify-content: space-between;
        align-items: center;margin-bottom: 10px; ">
        <div style=" display: flex;
        align-items: center; ">
            <div>
                    <mat-select style="border: none;
                    border-radius: 3px;
                    font-size: 16px;
                    padding: 10px;
                    margin: 5px;
                    color: #2196f3;background-color: white;width: 200px;" disableOptionCentering [value]="'Choose Date'" (selectionChange)="selectionChange($event.value)">
                      <mat-option value="Choose Date" aria-selected="true">Choose Date</mat-option>
                      <mat-option value="Choose Date Range">Choose Date Range</mat-option>
                    </mat-select>

                </div>
                <div *ngIf="isDateRange" style="margin-right:5px">
                    <mat-form-field appearance="outline">
                        <mat-label style="font-size: 17px;">From Date</mat-label>
                    <input style="
                    color: #2196f3;" matInput type="date" id="dateFrom" placeholder="Start Date" webkitdirectory (change)="dateSelected($event)">
                     </mat-form-field> 
                 </div>
                <div>
                    <mat-form-field appearance="outline">
                        <mat-label *ngIf="isDateRange" style="font-size: 17px;">To Date</mat-label>
                        <mat-label *ngIf="!isDateRange"  style="font-size: 17px;">Select Date</mat-label>
                    <input style="
                    color: #2196f3;"  matInput type="date" id="dateTo" placeholder="Start Date" webkitdirectory (change)="dateSelected($event)">
                     </mat-form-field> 
                 </div>
                
                 </div>
                 <div>
                 <button style="border: none;
                 border-radius: 3px;
                 font-size: 16px;
                 padding: 10px;
                 margin: 5px;
                 color: #2196f3;" (click)="exportData()">Export to CSV
                </button>
                </div>
            </div>
        <p *ngIf="!loading"><ag-grid-angular
                style="width:inherit" 
                class="ag-theme-alpine"
                [rowData]="rowData"
                [pagination] ="true"
                (gridReady)="onGridReady($event)"
                [paginationPageSize]="paginationPageSize"
                [suppressExcelExport]="true"
                [columnDefs] ="columnDefs"
                [domLayout] ="domLayout">
            </ag-grid-angular></p>
        </div>
    </div>