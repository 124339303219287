import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { env } from 'process';
import { Observable } from 'rxjs';
import { async } from 'rxjs/internal/scheduler/async';
import { AdminService } from 'src/app/shared-services/adminservice.service';
@Component({
  selector: 'app-activity',
  templateUrl: './userActivityRep.component.html',
  styleUrls: ['./userACtivityRep.component.scss']
})
export class UserActivityRepComponent implements OnInit {
    gridApi;
    paginationPageSize;
    domLayout;
    public isDateRange:boolean;
    public DateFrom ="";
  public DateTo ="";
  public dateValue ="all";
  public roleId = '3';
  public loading = true;
  users = [{
    id: 3, name: 'All'},
  {
    id: 1, name: 'Caregiver'},
  {
    id: 2, name: 'Recruiter'},
  ];
 
     rowData =[];
    ngOnInit(): void {
        this.adminService.getUserActivityRep(this.dateValue+ "&"+'3').subscribe(userObject=>{
            console.log("userObject", userObject);
            this.rowData = userObject.responseData;
            this.loading = false;
          })
//           const utcTimestamp = "2021-06-22T12:03:43.000+0000";
// const date = new Date(utcTimestamp);

// // Automatically convert to the user's local timezone
// const localTime = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
    }
    constructor(
        private adminService:AdminService,
        private router:Router,
        private route:ActivatedRoute,
        private datePipe: DatePipe
      ){
        this.paginationPageSize =10;
        this.domLayout = 'autoHeight';
      }
    columnDefs = [{headerName:"User Id", field: "email", sortable:true, unSortIcon: true, filter:true,resizable: true},
      {headerName:"User Name", field: "fullName", sortable: true, unSortIcon: true, filter:true, resizable: true}, 
  {headerName:"Date/Time", field: "activityTime", sortable: true, resizable: true,valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
    filterParams:{
      comparator: (filterLocalDateAtMidnight, cellValue)=> {
        var dateAsString = this.dateformatter(cellValue);
        if (dateAsString == null) return 0;
        var dateParts = dateAsString.split('/');
        const day = Number(dateParts[1]);
        const month = Number(dateParts[0]) - 1;
        const year = Number(dateParts[2]);
        var cellDate = new Date(year, month, day);
        console.log("cellDate", cellDate);
       if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        } else if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        } else {
          return 0;
        }
      }},},
  {headerName:"Page Visited", field: "pageVisited",filter:true, sortable: true, resizable: true},
  {headerName:"Activity", field: "activity",filter:true, sortable: true, resizable: true},
  { field: "Role",filter:true,sortable: true, resizable: true, valueGetter:this.convertData, valueFormatter: function (params) {
    return params.value; // Return the formatted value for display
  }}
];
convertData(params){
 if(params.data.roleId == 1){
      return 'Caregiver';
    }
    else if(params.data.roleId == 2){
    return 'Recruiter';
    }
    else{
      return 'Admin';
    }
}
/*
filter:'agTextColumnFilter',
  filterParams:{
    comparator: (filterText, cellValue) => {
      // Convert both the filter text and cell value to lowercase for case-insensitive comparison
    const lowerCaseFilterText = filterText.toLowerCase();
    const lowerCaseCellValue = cellValue.toLowerCase();
    console.log("lowerCaseCellValue", lowerCaseCellValue);
    // Use the includes method to check if the cell value contains the filter text
    return lowerCaseCellValue.includes(lowerCaseFilterText);
    }}
*/
selectionChange(event){
  this.rowData =[];
  this.dateValue ="all"
  console.log(event);
  var DateTo;
  DateTo = document.querySelectorAll("#dateTo")[0];
    DateTo.value ="";
    this.adminService.getUserActivityRep(this.dateValue +"&"+this.roleId).subscribe(userObject=>{
      console.log("userObject", userObject);
      this.rowData = userObject.responseData;
      this.loading = false;
    })
if(event == "Choose Date Range"){
this.isDateRange = true;
}else{
    this.isDateRange = false;
}
}
exportData(){
  //this.gridApi.api.exportDataAsCsv();
  const currentdate = new Date();
  var date = currentdate.getDate();
  var month = currentdate.getMonth()+1;
  var year = currentdate.getFullYear();
  var fullDate = date+"/"+ month+"/"+year
 this.gridApi.api.exportDataAsCsv({
  processCellCallback:(params)=> {
    if (params.column.colId === 'Role') {
      const value = params.node.data.roleId;
      return value === 1 ? 'Caregiver' : 'Recruiter';
    }
    if(params.column.colId === 'activityTime'){
    return this.dateformatter(params);
    }
    return params.value;
  },
  fileName: 'userActivityReport'+fullDate+'.csv'
 })
}
dateformatter(params){ /** for date format */
if(params.value !=null && params.value !=undefined){
 // dateSplit = params.value.split("T");
  var convertedDate = new Date(params.value);


// else{
//   dateSplit = params.split("T");
// }
 // var dateSplit = params.value.split("T");
  
  var date = convertedDate.getDate();
  var month = convertedDate.getMonth()+1;
  var year = convertedDate.getFullYear();
  var hour = convertedDate.getHours();
  var minutes = convertedDate.getMinutes();
  var shift ="AM";
  if(hour>12){
    shift = "PM";
    hour -= 12;
  }
  var formattedHour = hour.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  var fullDate = date+"/"+ month+"/"+year+ " "+formattedHour+":"+formattedMinutes+" "+shift;
  return fullDate;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}

dateSelected(event){

  var value ="all";
  var DateFrom;
  var DateTo;
  
  if(this.isDateRange && event.target.value != "" && event.target.value != null ){
   DateFrom = document.querySelectorAll("#dateFrom")[0];
   DateFrom = DateFrom.value
   DateTo = document.querySelectorAll("#dateTo")[0];
   DateTo = DateTo.value;

if(DateFrom !=null && DateTo !=null && DateFrom !="" && DateTo !=""){
  value =DateFrom +"to" +DateTo;
}
else if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
  value ="DateFrom"+DateFrom;
}else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
  value ="DateTo" +DateTo;
}
  }
else if(this.isDateRange && (event.target.value == "" || event.target.value == null)){
  DateFrom = document.querySelectorAll("#dateFrom")[0];
  DateFrom = DateFrom.value
  DateTo = document.querySelectorAll("#dateTo")[0];
  DateTo = DateTo.value;
 if(DateFrom !=null && (DateTo ==null || DateTo =="") && DateFrom !=""){
   
   value ="DateFrom"+DateFrom;
 }
 else if((DateFrom ==null || DateFrom == "") && DateTo !=null && DateTo !=""){
   value ="DateTo" +DateTo;
 }
 else{
   value = "all";
 }
  }else if(!this.isDateRange && (event.target.value == "" || event.target.value == null)){
    value = "all"
  }else if(!this.isDateRange && (event.target.value != "" || event.target.value != null)){
    value = event.target.value;
  }
  this.dateValue = value;
  this.adminService.getUserActivityRep(value+"&"+this.roleId).subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
}
timeFormatter(params){ /** for date format */
var dateSplit;
// const utcTimestamp = params.value;
// const date = new Date(utcTimestamp);

// // Automatically convert to the user's local timezone
// const localTime = this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
if(params.value !=null && params.value !=undefined){
//  dateSplit = params.value.split(" ");

// else{
//   dateSplit = params.split("T");
// }
   dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[1].split(":");
  var hour = dateSplit1[0];
  var minutes = dateSplit1[1];
  //var year = dateSplit1[0];
  var formattedHour = hour.toString().padStart(2, '0');
  var formattedMinutes = minutes.toString().padStart(2, '0');
  var fullTime = formattedHour.concat(":", formattedMinutes);
  return fullTime;
}
 // return moment(params.value).format('DD/MM/YYYY'); 
}
usersList(params) {
  this.rowData =[];
  if(params == '1' || params == '2'){
    this.gridApi.columnApi.setColumnVisible('Role', false);
    this.gridApi.api.sizeColumnsToFit();
  }else{
    this.gridApi.columnApi.setColumnVisible('Role', true);
    this.gridApi.api.sizeColumnsToFit();
  }
  this.roleId = params;
  this.adminService.getUserActivityRep(this.dateValue+ "&" +params).subscribe(userObject=>{
    console.log("userObject", userObject);
    this.rowData = userObject.responseData;
    this.loading = false;
  })
}
  onGridReady(params) {  /** for recruiter table */
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi = params;
   this.gridApi.api.setColumnDefs(this.columnDefs);
   this.gridApi.api.setRowData(this.rowData); 
   //this.autoSizeAll();
  
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
 }
}