<div class="main-container" id="homeScreen">
  <div class="sub-container">
    <div class="text-container">
      <p class="heading">
        Streamlined communication between caregivers and recruiters.
      </p>
      <p class="para">
        Genie Credentials allows Caregivers to provide their credentials and documents all to one place, while serving as a
        hub for employers and recruiters to request, view, and verify those credentials easily.
      </p>
      <div class="userButtons">
        <button (click)="caregiver()">Caregiver</button>
        <button (click)="recruiter()">Recruiter</button>
        </div>
      </div>
    </div>
  </div>
  <footer style="text-align: center;font-family: 'Century Gothic';">
            
    <div style="display:flex;justify-content: space-around;padding: 10px;background-color: #ebebeb;color: #353535;align-items: center;">
    <div style="display: flex;">
    <div>
            <i style="font-size: 50px;" class="fa fa-phone-alt" aria-hidden="true"></i>
        </div>
      <div style="padding-left: 10px;">
          <h4 style="margin-bottom: 0px !important;font-size: 18px;">
            Call Us
          </h4>
         <p style="font-size: 16px;"> 855-888-7333</p>

        </div>
      </div>
      <div style="display: flex;">
        <div>
              <a style="color: #353535" href="mailto:Admin@Gcredentials.com">
                <i style="font-size: 50px;" class="far fa-envelope" aria-hidden="true"></i>
              </a>
            </div>
          <div style="padding-left:10px">
              <h4 style="margin-bottom: 0px !important;font-size: 18px;">
                <a style="color: #353535" href="mailto:Admin@Gcredentials.com">Email Us</a>
              </h4>


              <p>
                <a style="font-size: 16px;color: #353535" href="mailto:Admin@Gcredentials.com">Admin@Gcredentials.com</a>
              </p>
            </div>
</div>
          <!-- .et_pb_blurb_content -->
        <!-- .et_pb_blurb -->
      <div style="display: flex;">
        <div>
        <i style="font-size: 50px;" class="fa fa-map-marker-alt" aria-hidden="true">
        </i>
        </div>
        <div style="padding-left: 10px;">
        <!--<span style="font-size: 18px;">Address: </span>-->
        <p style="font-size: 16px;">50 Millstone Road</p>
        <p style="font-size: 16px;">Building 100, Suite 100</p>
        <p style="font-size: 16px;">East Windsor NJ 08520</p>
      </div>
      </div>
      </div>
      <div style="padding-top: 10px;">
        @Powered by <a href="https://geniehealthjobs.com" target="_blank"><img width="100px" src="../../../assets/GenieLogo.png"></a>
        </div>
    
    </footer>