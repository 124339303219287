import { CommonModule } from "@angular/common"
import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { AlertComponent } from "./alert.component"

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule
  ],
    declarations: [AlertComponent],
   
    providers: [
  
  ],
    exports:[AlertComponent]
  })

export class AlertsModule {

}