<div class="container-fluid">
<h1 style="font-family: 'Acumin Variable Concept !important';"> Document Management</h1> 
<div style="padding:10px;border-radius: 5px;height:630px">
    <div *ngIf="loading" class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span>Loading</span>
  </div>
<p *ngIf="!loading && !text">
    <ag-grid-angular 
    #agGrid1
    style="width:inherit;" 
    id="myGrid"
    class="ag-theme-alpine recDocDetails"
    [rowData]="rowData" 
    (gridReady)="onGridReady($event)"
    [domLayout]="domLayout"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [suppressDragLeaveHidesColumns] = true
    [suppressRowClickSelection] = true
    [suppressCellSelection] = 'true'
    [rowSelection]="rowSelection"
    [isRowSelectable]="isRowSelectable"
    [frameworkComponents]="frameworkComponents"
    [defaultColDef] ="defaultColDef"
    rowSelection="multiple"
    (rowSelected)="onRowSelected($event)"
    [columnDefs]="columnDefs"
    >
  </ag-grid-angular>
    </p>
    <div style="text-align: right;" *ngIf="!loading && !text">
      <button *ngIf="!isDownloadLoading" class="btn btn-primary" (click)="btnClickedHandler()" type="submit" [disabled]="!isSelected">Download Documents</button>
      <button *ngIf="isDownloadLoading" class="btn btn-primary"><i class="fa fa-spinner"></i></button>
      </div>
    <div *ngIf="text" style="padding:10px;border-radius: 5px;height:630px;text-align: center;">
      <div style="text-align: left;background-color: #ffffff;padding:10px;border-radius: 5px;">
        <p style="color:#2777BA;font-size:20px;font-family: 'Acumin Variable Concept'"><i style="margin-right:10px" class="fa fa-info-circle"></i>No Documents Found</p>
        <p style="font-size:18px;color: #000;font-family: 'Century Gothic'">Sorry, there are no approved documents here.</p>
        </div>
        </div> 
</div>
</div>