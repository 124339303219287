import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { ValidationService } from 'src/app/authentication/services/validation.service';
import { ShareDocumentsService } from 'src/app/shared-services/share-documents.service';
import * as moment from 'moment'; 
import { UserService } from 'src/app/shared-services/userservice.service';
import { AlertService } from 'src/app/shared-services/alert.service';
@Component({
    selector: 'app-request-document',
    templateUrl: './request-documents.component.html',
    styleUrls: ['./request-documents.component.scss']
  })
  
export class RequestDocumentsComponent implements OnInit  {
    requestDocForm: FormGroup;
    displayDocs:Boolean;
    successMessage;
    response;
    public frameworkComponents
   public gridApi;
   public text:boolean;
   public gridColumnApi;
   public rowClassRules;
    params: any;
    public caregiverFirstName;
    public caregiverLastName;
    public caregiverEmail;
   private userId:number;
   public isloading:boolean;
   public isRequestLoading:boolean;
   public isSelected:boolean;
   public isCollapse:boolean = true;
   public isExpand:boolean;
   public errorMessage;
   public isError:boolean;
   public checkboxCheck;
   public selectedId = [];
   public  rowData =[];
  public rowData1 = [];
  public domLayout;
  public paginationPageSize;
  public pagination;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
   // public rowData:any;
   @ViewChild("agGrid", {static: false}) agGrid:AgGridAngular;
    constructor(private formBuilder: FormBuilder,
      private httpClient: HttpClient,
      private validationService:ValidationService,
      public alertService:AlertService,
      private shareDocumentsService: ShareDocumentsService,
      private userService:UserService){
       
        this.domLayout = 'autoHeight';
        this.paginationPageSize = 10;
    }
    ngOnInit(){
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
      sessionStorage.setItem('isNextTab', 'yes');

      this.getUserProfile();
      document.body.style.backgroundImage = "none";
      //document.body.style.backgroundImage ="url('../../../assets/shutterstock_331019402-1.jpg')";
        this.createRequestDocForm();
        this.displayDocs=false;
        this.shareDocumentsService.documentId =[];
    }
    createRequestDocForm(){
this.requestDocForm = this.formBuilder.group({
    details:['', [Validators.required, this.validationService.checkPhoneorEmail]],
    
})
    }
    getErrorEmailorphone() {
        return this.requestDocForm.get('details').hasError('required') ? 'Email/PhoneNumber is required.' :
          this.requestDocForm.get('details').hasError('emailorphonerequire') ? 'Provided Email/PhoneNumber is not valid.' : '';
      }
     
      columnDefs = [
       {field:"documentName",
       headerCheckboxSelection: params => {
        const checkBox = document.createElement('input');
        checkBox.setAttribute('type', 'checkbox');
        checkBox.checked = false;
      //  console.log('isBooked', params)
        const label = document.createElement('label');
        const title = document.createTextNode(params.colDef.headerName);
        label.appendChild(checkBox);
        label.appendChild(title);
        return label;
      } , 
        checkboxSelection: true, width:300, filter:true, resizable: true},
        {field:"documentType", width:300, filter:true, resizable: true},
   {field:"documentDescription", width:265, resizable: true}
  ,  {headerName:"Issued Date", width:250, field:"startDate", valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
  filterParams:{
    comparator: (filterLocalDateAtMidnight, cellValue)=> {
      var dateAsString = this.dateformatter(cellValue);
      if (dateAsString == null) return 0;
      var dateParts = dateAsString.split('/');
      const day = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[2]);
      var cellDate = new Date(year, month, day);
  
     if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }},
   resizable: true},
  {field:"expirationDate", width:225, valueFormatter:this.dateformatter,
  filter: 'agDateColumnFilter',
  filterParams:{
    comparator: (filterLocalDateAtMidnight, cellValue)=> {
      var dateAsString = this.dateformatter(cellValue);
      if (dateAsString == null) return 0;
      var dateParts = dateAsString.split('/');
      const day = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const year = Number(dateParts[2]);
      var cellDate = new Date(year, month, day);
  
     if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      } else {
        return 0;
      }
    }},
   resizable: true},
 ];
 columnDefs1 = [
  {headerName:"Caregiver Email/UserName", field:"email", resizable: true},
  {headerName:"Caregiver FirstName",field:"firstname", resizable: true},
   {headerName:"Caregiver LastName",field:"lastname", resizable: true},
{headerName:"Caregiver PhoneNumber",field:"phoneNumber", resizable: true}
];
 
  defaultColDef= [{
    enableRowGroup: true,
    colResizeDefault:'shift',
    sortable: true,
    filter: true,
    resizable: true,
  }]
  onGridReady(params){
    
     // params.api.sizeColumnsToFit();
     if(window.matchMedia("(min-width: 1260px)").matches){
      params.api.sizeColumnsToFit();
    }
      window.addEventListener("resize", function () {
        setTimeout(function () {
          if(window.matchMedia("(min-width: 1260px)").matches){
          params.api.sizeColumnsToFit();
        }
        });
      });
    
    this.gridApi = params;
    //this.autoSizeAll();
   // this.gridApi.setDomLayout('autoHeight');
    //document.getElementById('#myGrid').style.height = '';
    this.params=params;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }
  
  onGridReady1(params){
    
    // params.api.sizeColumnsToFit();
    if(window.matchMedia("(min-width: 1260px)").matches){
     params.api.sizeColumnsToFit();
   }
     window.addEventListener("resize", function () {
       setTimeout(function () {
         if(window.matchMedia("(min-width: 1260px)").matches){
         params.api.sizeColumnsToFit();
       }
       });
     });
   
   this.gridApi = params;
   //this.autoSizeAll();
  // this.gridApi.setDomLayout('autoHeight');
   //document.getElementById('#myGrid').style.height = '';
   this.params=params;
   this.gridApi = params.api;
   this.gridColumnApi = params.columnApi;
 }
 dateformatter(params){ /** for date format */
 if(params.value !=null){
  var dateSplit = params.value.split("T");
  var dateSplit1 = dateSplit[0].split("-");
  var date = dateSplit1[2];
  var month = dateSplit1[1];
  var year = dateSplit1[0];
  var fullDate = date.concat("/", month, "/", year);
  return fullDate;
 }
 
 // return moment(params.value).format('DD/MM/YYYY'); 
}
  onRowSelected(event) {
   // console.log(event);
   
    let i = event.rowIndex;
  
    this.checkboxCheck = event.node.selected;
    
  
    if(this.checkboxCheck) {
      this.isSelected = true;
      this.selectedId.push(this.rowData[i].caregiverDocId);
      //this.shareDocumentsService.aclicked(this.selectedId);
    //  console.log(this.selectedId);
    }
    else{
    //  this.shareDocumentsService.checked = false;
    var removeIndex1 = this.selectedId.map(function(item){return item;}).indexOf(event.data.caregiverDocId);
    if(removeIndex1 != -1){
      this.selectedId.splice(removeIndex1, 1);
      if(this.selectedId.length == 0){
        this.isSelected = false;
      }
      else{
        this.isSelected = true;
      }
    //  console.log(this.selectedId);
    
    }
  }
  //  console.log(event.rowIndex);
  
  }
  
  btnClickedHandler() {
  // const data:any={};
  this.isRequestLoading = true;
    const inputObj:any = {};
    inputObj.nurseId = this.userId;
   inputObj.documentIds = this.selectedId;
//data.inputObj = inputObj;
//data.loginUserId = parseInt(localStorage.getItem('userid'));
   this.shareDocumentsService.requestDocs(inputObj).
   subscribe((res)=>{
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.selectedId =[];
    if(res.responseMessage == 'SUCCESS' && res.responseCode == 200){
      this.alertService.success('Success! your documents request sent to selected caregiver.', this.options);
      this.requestDocForm.get('details').reset('');
      this.displayDocs = false;
    }
    this.isRequestLoading =false;
   })
   
  }
  backbtnHandler() {
    window.scrollTo( 0, 0 );
    document.getElementsByTagName('html')[0].scrollTop=0;
    this.displayDocs = false;
    this.text = false;
    this.selectedId = [];
    this.requestDocForm.get('details').reset('');
    document.getElementById('requestDocId').style.height = '700px';
   // document.body.style.backgroundImage ="url('../../../assets/shutterstock_331019402-1.jpg')";
  }
  formSubmit(){
    this.isloading= true;
    this.shareDocumentsService.searchCargiver(this.requestDocForm.get('details').value.trim())
    .subscribe((result)=>{
    //  console.log('result', result);
      if(result.body.response == "NO_USER_FOUND"){
       this.alertService.error('Caregiver with this email/phonenumber is not found please confirm the email/phonenumber with caregiver', this.options);
        this.isloading = false;
       // this.isError = true;
       // this.errorMessage ="Caregiver with this email/phoneNumber is not found please confirm the email with caregiver"
      }

      if(result){
      this.rowData1 = result.body.cgBeanList;
        this.userId=result.body.cgBeanList[0].userId;
        this.shareDocumentsService.findNurseDocs(result.body.cgBeanList[0].userId)
        .subscribe((res)=>{
          window.scrollTo( 0, 0 );
          document.getElementsByTagName('html')[0].scrollTop=0;
          this.isloading = false;
          document.body.style.backgroundImage = 'none';
          this.successMessage = JSON.stringify(res);
          this.response = JSON.stringify(res);
          this.caregiverEmail = res.nurseEmail;
          this.caregiverFirstName = res.nurseFirstName;
          this.caregiverLastName = res.nurseLastName;
          this.rowData = res.documentList;
          if(res.documentList.length > 8){
            document.getElementById('requestDocId').style.height = '758px';
            document.getElementById('subRequestDocId').style.height ='auto';
           }
          if(res.documentList.length > 10){
           this.pagination = true;
          }
          if(res.documentList.length === 0){
            this.text = true;
            this.displayDocs = false;
          }
          else{
            for(let i=0;i<res.documentList.length; i++) {
              let rowData = res.documentList[i].documentName;
            }
            this.displayDocs = true;
          }
          return res;
        });
      }
      return result;
    },
    (err)=>{
      window.scrollTo( 0, 0 );
      document.getElementsByTagName('html')[0].scrollTop=0;
     // console.log('error', err);
    }
    )
  }
  expand(){
    this.isExpand =false;
    this.isCollapse = true;
    if(this.rowData.length > 8){
      document.getElementById('requestDocId').style.height = '758px';
    }
    else if(this.rowData.length <= 8){
      document.getElementById('requestDocId').style.height = '700px';
    }
  }
  collapse(){
    if(this.rowData.length > 8){
    document.getElementById('requestDocId').style.height = '863px'
    }
    else if(this.rowData.length <= 8){
      document.getElementById('requestDocId').style.height = '758px';
    }
    this.isExpand =true;
    this.isCollapse = false;
  }
  getUserProfile(){
    this.userService.UserProfile()
    .subscribe((res)=>{
      return res;
    },
    (err)=>{
    }
    )
  }
}
